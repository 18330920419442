import { FunctionComponent, useMemo } from 'react';
import './styles/ChipsNavigation.scss';

import { Badge, Chip, Stack, StackProps } from '@mui/material';

import { IMenuItem, TColor } from '@cw/models/shared';
import { classNames } from '@cw/utils';

export interface IChipMenuItem extends IMenuItem {
  badgeCount?: number;
  badgeColor?: TColor;
}

interface IChipsNavigationProps extends StackProps {
  options: IChipMenuItem[];
  activeItem: string;
  optionSelected: (option: IMenuItem) => void;
}
export const ChipsNavigation: FunctionComponent<IChipsNavigationProps> = (props: IChipsNavigationProps) => {

  const {
    options,
    activeItem,
    optionSelected,
    ...stackProps
  } = props;

  const visibleOptions = useMemo(() => options.filter(x => !x.hidden), [options]);

  const wrapChip = (menuItem: IChipMenuItem, index: number) => {
    const chip = (
      <Chip
        key={menuItem.value ?? index}
        onClick={() => optionSelected(menuItem)}
        icon={menuItem.icon}
        label={menuItem.text}
        color={activeItem === (menuItem.value ?? menuItem.text) ? 'primary' : 'default'}
      />
    );

    if (!menuItem.badgeCount) {
      return chip;
    } else {
      return (
        <Badge key={menuItem.value ?? index} badgeContent={menuItem.badgeCount} color={menuItem.badgeColor ?? 'default'} className='cw-chip-badge'>
          {chip}
        </Badge>
      )
    }
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      columnGap={2}
      paddingBottom={2}
      className={classNames('cw-chips-navigation', {
        'allow-overflow': visibleOptions.some(x => (x.badgeCount ?? 0) > 0)
      })}
      {...stackProps}
    >
      {visibleOptions.map((x, index) => wrapChip(x, index))}
    </Stack>
  )
}