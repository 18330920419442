import { TColor } from "@cw/models/shared";

const ALPHABET_COLORS: { color: TColor, letters: string[] }[] = [
  {
    color: 'primary',
    letters: ['a', 'h', 'o', 'v']
  },
  {
    color: 'secondary',
    letters: ['b', 'i', 'p', 'w']
  },
  {
    color: 'tertiary',
    letters: ['c', 'j', 'q', 'x']
  },
  {
    color: 'success',
    letters: ['d', 'k', 'r', 'y']
  },
  {
    color: 'warning',
    letters: ['e', 'l', 's', 'z']
  },
  {
    color: 'error',
    letters: ['f', 'm', 't']
  },
  {
    color: 'dark',
    letters: ['g', 'n', 'u']
  },
];

export const getColorForInitials = (initials: string): TColor => {
  const firstInitial = !initials ? '' : initials.substring(0, 1).toLowerCase();
  const colorToUse = ALPHABET_COLORS.find(x => x.letters.includes(firstInitial)) ?? ALPHABET_COLORS[0];
  return colorToUse.color;
}