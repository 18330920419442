import { logger } from "@cw/services/logger";
import { useEffect } from "react";

export const useWakeLock = () => {
  useEffect(() => {
    try {
      let wakeLock: WakeLockSentinel | null = null;
  
      navigator.wakeLock.request('screen').then((wl) => {
        wakeLock = wl;
      }).catch((err) => {
        if ((err?.message)?.toString()?.toLowerCase()?.includes('page is not visible')) {
          return;
        }
  
        logger.error('Error during useWakeLock::useEffect::wakeLock.request', err);  
      });
  
      return () => {
        if (wakeLock) {
          wakeLock.release();
        }
      }
    } catch (err: any) {
      if ((err?.message)?.toString()?.toLowerCase()?.includes('page is not visible')) {
        return;
      }

      logger.error('Error during useWakeLock::useEffect', err);
    }
  }, []);
}