import { useState, useEffect, useMemo } from 'react';

import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  TablePagination as MuiTablePagination,
  Paper as MuiPaper,
  SxProps,
  Theme,
  Checkbox,
  Stack,
} from '@mui/material';

import { camelCaseToUserText, getColor } from '@cw/utils';

import { ITableProps, TableColumn } from './models';
import { DataTableRow } from './DataTableRow';
import { NoDataTableRow } from './NoDataTableRow';
import { InformationalHeader } from '../InformationalHeader';

const tablePageSizes: number[] = [5, 10, 25, 50, 100];

export function Table<T>(props: ITableProps<T>) {
  const {
    data,
    columns,
    keyAttribute,
    getRowClickRoute,
    onRowClick,
    addWrapperComponent,
    addPagination,
    defaultRowsPerPage,
    noDataContent,
    checkboxSelection,
    checkboxSelectedRowIds,
    checkboxDisabledRowIds,
    checkboxSelectedRowIdsChanged
  } = props;

  const [currentPageSize, setCurrentPageSize] = useState(defaultRowsPerPage && tablePageSizes.includes(defaultRowsPerPage) ? defaultRowsPerPage : tablePageSizes[1]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pagedData, setPagedData] = useState<T[]>([]);
  
  const visibleColumns = useMemo(() => columns.filter(x => !x.hidden), [columns]);
  const idDataKey = useMemo(() => keyAttribute ?? ('id' as keyof T), [keyAttribute]);
  const dataIds = useMemo(() => (data ?? []).map(x => x[idDataKey] as any), [data, idDataKey]);
  const checkboxState = useMemo<'none' | 'checked' | 'indeterminate'>(() => {
    const selectedIdsCount = dataIds.filter(x => (checkboxSelectedRowIds ?? []).indexOf(x) >= 0).length;
    return selectedIdsCount === 0 ? 'none' : selectedIdsCount === dataIds.length ? 'checked' : 'indeterminate';
  }, [checkboxSelectedRowIds, dataIds]);

  const getColumnLabel = (column: TableColumn<T>): string => {
    return column.label || camelCaseToUserText(column.key as string);
  };

  const handleHeaderCheckboxChanged = () => {
    const selectedRowIdsClone = Object.assign([], checkboxSelectedRowIds ?? []);
    if (checkboxState === 'indeterminate' || checkboxState === 'none') {
      // Select All Items not already selected
      dataIds.forEach(x => {
        if (!selectedRowIdsClone.includes(x) && !(checkboxDisabledRowIds ?? []).includes(x)) {
          selectedRowIdsClone.push(x);
        }
      });
    } else if (checkboxState === 'checked') {
      // Unselect all selected items
      dataIds.forEach(x => {
        const index = selectedRowIdsClone.indexOf(x);
        if (index >= 0) {
          selectedRowIdsClone.splice(index, 1);
        }
      });
    }

    if (checkboxSelectedRowIdsChanged) {
      checkboxSelectedRowIdsChanged(selectedRowIdsClone);
    }
  };
  const handleRowCheckboxSelectionChanged = (row: any) => {
      const id = row[idDataKey];
      const selectedRowIdsClone = Object.assign([], checkboxSelectedRowIds ?? []);
      const existingIndex = selectedRowIdsClone.indexOf(id);
      if (existingIndex >= 0) {
          selectedRowIdsClone.splice(existingIndex, 1);
      } else {
          selectedRowIdsClone.push(id);
      }

      if (checkboxSelectedRowIdsChanged) {
        checkboxSelectedRowIdsChanged(selectedRowIdsClone);
      }
  };

  useEffect(() => {
    if (!addPagination) {
      setPagedData(data);
      setCurrentPageIndex(0);
      return;
    }

    let startIndex: number;
    let endIndex: number;
    let newPageIndex = currentPageIndex;
    do {
      startIndex = currentPageSize * newPageIndex;
      endIndex = Math.min(startIndex + currentPageSize, data.length);

      if (data.length > 0 && startIndex >= data.length) {
        newPageIndex--;
      }
    } while (data.length > 0 && startIndex >= data.length);

    setPagedData(data.slice(startIndex, endIndex));
    if (newPageIndex !== currentPageIndex) {
      setCurrentPageIndex(newPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, currentPageIndex, data, addPagination]);

  const TablePagination = ({ sx }: { sx?: SxProps<Theme> }) => (
    <MuiTablePagination
      rowsPerPageOptions={tablePageSizes}
      colSpan={visibleColumns.length + (checkboxSelection ? 1 : 0)}
      count={data.length}
      rowsPerPage={currentPageSize}
      page={currentPageIndex}
      onPageChange={(_, newPage: number) => setCurrentPageIndex(newPage)}
      onRowsPerPageChange={(e) =>
        setCurrentPageSize(parseInt(e.target.value, 10))
      }
      sx={sx}
    />
  );

  const tableRoot = (
    <MuiTable stickyHeader={true}  sx={{ width: '100%' }}>
      <MuiTableHead
        sx={{
          '.MuiTableCell-stickyHeader': {
            background: 'var(--color-light)'
          },
          borderBottomColor: 'black',
        }}
      >
        <MuiTableRow>
          {checkboxSelection && (
            <MuiTableCell sx={{
              width: '42px',
              padding: '0px'
            }}
            >
              <Checkbox
                checked={checkboxState === 'checked'}
                indeterminate={checkboxState === 'indeterminate'}
                onChange={handleHeaderCheckboxChanged}
              />
          </MuiTableCell>
          )}
          {visibleColumns.map((column, index) => (
            <MuiTableCell
              key={index}
              sx={{
                padding: '30px 16px',
                color: column.textColor
                  ? getColor(column.textColor)
                  : undefined,
              }}
            >
              <InformationalHeader variant='body2' fontWeight='bold' tooltip={column.columnHeaderInfoTooltip}>
                {getColumnLabel(column)}
              </InformationalHeader>
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>

      <MuiTableBody>
        {data?.length > 0 ? (
          pagedData.map((row, index) => (
            <DataTableRow
              row={row}
              columns={visibleColumns}
              key={keyAttribute ? (row[keyAttribute] as any) : index}
              getRowClickRoute={getRowClickRoute}
              onRowClick={onRowClick}
              checkboxSelection={checkboxSelection}
              checkboxDisabled={checkboxSelection && (checkboxDisabledRowIds ?? []).includes(row[idDataKey])}
              checkboxChecked={checkboxSelection && (checkboxSelectedRowIds ?? []).includes(row[idDataKey])}
              checkboxSelectionChanged={() => handleRowCheckboxSelectionChanged(row)}
            />
          ))
        ) : (
          <NoDataTableRow columnCount={visibleColumns.length + (checkboxSelection ? 1 : 0)} noDataContent={noDataContent} />
        )}
      </MuiTableBody>

      {addPagination && data?.length > 0 && (
        <MuiTableFooter>
          <MuiTableRow>
            <TablePagination />
          </MuiTableRow>
        </MuiTableFooter>
      )}
    </MuiTable>
  );

  // if (!addWrapperComponent) {
  //   return tableRoot;
  // }

  return (
    <MuiTableContainer
      component={addWrapperComponent ? MuiPaper : Stack}
      sx={{
        width: '100%',
      }}
    >
      {tableRoot}
    </MuiTableContainer>
  );
}
