import { FunctionComponent } from 'react';

import { ProgressSpinner } from './core';
import { Stack } from '@mui/material';

interface IPageLoaderProps {
    noBackground?: boolean;
}
export const PageLoader: FunctionComponent<IPageLoaderProps> = (props: IPageLoaderProps) => {
    const { noBackground } = props;

    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
                backgroundColor: !noBackground ? 'rgba(var(--color-background-rgb), 0.6)' : undefined,
                zIndex: '999'
            }}
            className='absolute-full-page'
        >
            <ProgressSpinner color='primary' />
        </Stack>
    )
};