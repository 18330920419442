import { useState, useEffect, FunctionComponent } from 'react';

import { IGeoPoint } from '@cw/models/shared';

export interface IGeoMarker {
    position: IGeoPoint;
}

interface IGeoMarkerProps {
    map: google.maps.Map;
    position: IGeoPoint;
}
export const GeoMarker: FunctionComponent<IGeoMarkerProps> = (props: IGeoMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker({
                zIndex: 1,
                optimized: true
            }));
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);

    useEffect(() => {
        if (!marker) {
            return;
        }

        const currentMarkerPosition = marker.getPosition();
        
        if (!currentMarkerPosition || currentMarkerPosition.lat() !== props.position.latitude || currentMarkerPosition.lng() !== props.position.longitude) {
            marker.setPosition({
                lat: props.position.latitude,
                lng: props.position.longitude
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marker, props.position]);

    useEffect(() => {
        if (!marker) {
            return;
        }

        marker.setMap(props.map);
    }, [marker, props.map]);

    return null;
};