type Callback<T> = (data: T) => void;

export enum PubSubEvent {
  IndexDbRowChanged = 'IndexDbRowChanged',
  AdjudicatorOnTheDayGoTo = 'AdjudicatorOnTheDayGoTo',
  LiveMusicPlayerActionTaken = 'LiveMusicPlayerActionTaken',
  LiveMusicPlayerSaveAllSongs = 'LiveMusicPlayerSaveAllSongs'
}

class PubSub {
    private subscribers: Record<PubSubEvent, Callback<any>[]> = {
      IndexDbRowChanged: [],
      AdjudicatorOnTheDayGoTo: [],
      LiveMusicPlayerActionTaken: [],
      LiveMusicPlayerSaveAllSongs: []
    };

    // Subscribe to an event
    subscribe<T>(event: PubSubEvent, callback: Callback<T>): () => void {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        const index = this.subscribers[event]!.push(callback) - 1;

        // Return an unsubscribe function
        return () => {
            this.subscribers[event]!.splice(index, 1);
        };
    }

    // Publish an event
    publish<T>(event: PubSubEvent, data: T): void {
        if (!this.subscribers[event] || this.subscribers[event].length < 1) return;
        
        this.subscribers[event]!.forEach(callback => callback(data));
    }
}

const pubSub = new PubSub();
export default pubSub;