import { FunctionComponent } from 'react';

import { Typography, TypographyProps } from '@mui/material';

import { InformationalWrapper } from './InformationalWrapper';

interface IInformationalHeaderProps extends TypographyProps {
  tooltip?: string;
}
export const InformationalHeader: FunctionComponent<IInformationalHeaderProps> = (props: IInformationalHeaderProps) => {

  const {
    tooltip,
    ...typographyProps
  } = props;

  return (
    <InformationalWrapper tooltip={tooltip}>
      <Typography {...typographyProps} />
    </InformationalWrapper>
  )
}