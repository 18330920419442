import { logger } from "@cw/services/logger";
import { isDate } from "./dateUtils";

import dayjs from "dayjs";

export const cleanApiResponse = (response: any): any => {
  if (!response) {
    return response;
  }
  
  if (Array.isArray(response)) {
      for (let i = 0; i < response.length; i++) {
          response[i] = cleanApiResponse(response[i]);
      }
  } else if (typeof response === 'object') {
      for (const key in response) {
          if (Array.isArray(response[key])) {
              for (let i = 0; i < response[key].length; i++) {
                  response[key][i] = cleanApiResponse(response[key][i]);
              }
          } else if (typeof response[key] === 'object') {
              response[key] = cleanApiResponse(response[key]);
          } else if (isDate(response[key])) {
              response[key] = dayjs(response[key]);
          }
      }
  } else if (isDate(response)) {
      return dayjs(response);
  }
  
  return response;
};

export const convertBlobToBase64 = (blob: Blob): Promise<string | null> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data);
    };
    reader.onerror = () => {
      logger.error('Error during blobToBase64: ', reader.error);
      resolve('');
    };
    reader.readAsDataURL(blob);
  });
};

export function searchItems<T>(searchText: string, itemSearchKeys: (keyof T)[], items: T[]): T[] {
  if (!searchText) {
    return items;
  }

  const searchTextParts = searchText.split(' ').map(x => x.trim().toLowerCase()).filter(x => x.length > 0);

  return items
    .filter(x => {
      let hasMatch = false;
      for (const key of itemSearchKeys) {
        const searchPropValueParts = `${x[key]}`.toLowerCase().split(' ').map(x => x.trim().toLowerCase()).filter(x => x.length > 0);
        hasMatch = searchPropValueParts.filter(y => searchTextParts.filter(z => y.includes(z)).length > 0).length > 0;
        if (hasMatch) {
          break;
        }
      }

      return hasMatch;
    });
}

export function convertToDictionary<T>(items: T[], keyAttribute: keyof T): Record<string, T> {
  return (items ?? []).reduce((acc, item) => {
    acc[item[keyAttribute] as string] = item;
    return acc;
  }, {} as Record<string, T>);
}