import React, { FunctionComponent, useEffect, useReducer } from 'react';

import { IReducerAction } from '@cw/models/shared';
import dayjs, { Dayjs } from 'dayjs';

interface IClockContext {
  timeNow: Dayjs;
}
const initialState: IClockContext = {
  timeNow: dayjs()
};
export const ClockContext = React.createContext<IClockContext>(initialState);

type TClockContextActions = 'SET_TIMENOW';
const ClockContextReducer = (
  state: IClockContext,
  action: IReducerAction<TClockContextActions>
): IClockContext => {
  switch (action.type) {
    case 'SET_TIMENOW':
      return { ...state, timeNow: action.payload };
    default:
      return state;
  }
};

interface IClockContextProviderProps {
    children: any;
}
export const ClockContextProvider: FunctionComponent<IClockContextProviderProps> = (props: IClockContextProviderProps) => {

  const [state, dispatch] = useReducer(ClockContextReducer, {
    ...initialState,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: 'SET_TIMENOW', payload: dayjs() });
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <ClockContext.Provider value={state}>
        {props.children}
    </ClockContext.Provider>
  )
};