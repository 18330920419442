import { FunctionComponent } from 'react';

import {
  Wrapper as GoogleMapsWrapper
} from "@googlemaps/react-wrapper";

import { useAppSettings } from "@cw/hooks";

export type TGoogleMapsLibraryLoaderStatus = 'LOADING' | 'SUCCESS' | 'FAILURE'

interface IGoogleMapsLibraryLoaderProps {
  loadingRender?: (status: TGoogleMapsLibraryLoaderStatus) => JSX.Element;
  statusChanged?: (status: TGoogleMapsLibraryLoaderStatus) => void;
  children: any;
}
export const GoogleMapsLibraryLoader: FunctionComponent<IGoogleMapsLibraryLoaderProps> = (props: IGoogleMapsLibraryLoaderProps) => {

  const {
    loadingRender,
    statusChanged,
    children
  } = props;

  const { appSettings } = useAppSettings();

  return (
    <GoogleMapsWrapper
      apiKey={appSettings.googleApiKey}
      render={loadingRender}
      libraries={['places']}
      callback={statusChanged}
    >
      {children}
    </GoogleMapsWrapper>
  )
}
