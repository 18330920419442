import { FunctionComponent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Button as MuiButton,
    ButtonProps,
    Menu as MuiMenu,
    Divider as MuiDivider,
    MenuItem as MuiMenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
    SxProps,
    Theme
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { IMenuItem } from '@cw/models/shared';
import { ProgressSpinner } from './ProgressSpinner';

export interface IButtonProps extends Omit<ButtonProps, 'href'> {
    loading?: boolean;

    routerLink?: string;

    menuOptions?: IMenuItem[];
    menuOptionSelected?: (menuItem: IMenuItem) => void;
    menuSx?: SxProps<Theme>;
}
export const Button: FunctionComponent<IButtonProps> = (props: IButtonProps) => {
    const {
        loading,
        menuOptions,
        menuOptionSelected,
        menuSx,
        routerLink,
        ...baseButtonProps
    } = props;

    const [buttonMenuAnchorEl, setButtonMenuAnchorEl] = useState<HTMLElement | null>(null);

    const startIcon = loading
        ? (<ProgressSpinner size={20} />)
        : props.startIcon;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (menuOptions && menuOptions.length > 0) {
            setButtonMenuAnchorEl(e.currentTarget as HTMLElement);
        } else if (props.onClick) {
            props.onClick(e);
        }
    };

    const handleMenuItemClicked = (option: IMenuItem) => {
        setButtonMenuAnchorEl(null);
        if (menuOptionSelected) {
            menuOptionSelected(option);
        }
    };

    const buildMenuItem = (menuItem: IMenuItem, index: number): React.ReactNode => {
        if (menuItem.isDivider) {
            return <MuiDivider key={index} />
        }

        const menuElement = (
            <MuiMenuItem key={index} onClick={() => !menuItem.routerLink ? handleMenuItemClicked(menuItem) : null}>
                {
                    menuItem.icon && (
                        <ListItemIcon>
                            {menuItem.icon}
                        </ListItemIcon>
                    )
                }
                <ListItemText disableTypography>
                    <Typography variant='body2'>{menuItem.text}</Typography>
                </ListItemText>
            </MuiMenuItem>
        );

        if (menuItem.routerLink) {
            return (
                <RouterLink to={menuItem.routerLink} key={index} onClick={() => setButtonMenuAnchorEl(null)}>
                    {menuElement}
                </RouterLink>
            )
        } else {
            return menuElement;
        }
    }

    const wrapButtonWithRouterLink = (element: JSX.Element): JSX.Element => {
        if (!routerLink) {
            return element;
        }

        return (
            <RouterLink to={routerLink}>
                {element}
            </RouterLink>
        )
    }

    return wrapButtonWithRouterLink(
        <>
            <MuiButton
                {...baseButtonProps}
                startIcon={startIcon}
                endIcon={menuOptions && menuOptions.length > 0 ? <KeyboardArrowDown /> : props.endIcon}
                disabled={props.disabled || loading}
                onClick={handleClick}
                aria-controls={!!buttonMenuAnchorEl ? 'basic-menu' : undefined}
                aria-haspopup={menuOptions && menuOptions.length > 0}
                aria-expanded={!!buttonMenuAnchorEl ? 'true' : undefined}
                aria-label={menuOptions && menuOptions.length > 0 ? 'Menu' : undefined}
            >
                {props.children}
            </MuiButton>

            {
                menuOptions && menuOptions.length > 0 && (
                    <MuiMenu
                        open={!!buttonMenuAnchorEl}
                        anchorEl={buttonMenuAnchorEl}
                        onClose={() => setButtonMenuAnchorEl(null)}
                        sx={menuSx}
                        slotProps={{
                            paper: {
                                sx: menuSx
                            }
                        }}
                    >
                        {
                            menuOptions.map((x, index) => buildMenuItem(x, index))
                        }
                    </MuiMenu>
                )
            }
        </>
    )
};