import { ILocalSettings, LocalDbNames, LocalDbStores } from "@cw/models/localDb";
import { convertWebmToMp3, getAudioFileDuration, IDeviceAudio } from "@cw/services/audioPickerService";

import { useLocalDb } from "./useLocalDb";
import { useConfirm, useSnackbar } from ".";
import { logger } from "@cw/services/logger";
import { getSafeFileName } from "@cw/utils/stringUtils";

interface IRecordDeviceAudioOptions {
  fileName: string;
  inputDeviceId?: string;
  onError?: (err: any) => void;
  onRecordingStarted?: () => void;
  onRecordingEnded?: () => void;
}

interface IAudioInputDevice {
  id: string;
  label: string;
}

export interface IAudioRecorderRef {
  stopAndFlushRecording: () => Promise<IDeviceAudio | null>;
  cancelRecording: () => void;
  activeInputDevice: IAudioInputDevice;
}


export const useAudioRecorder = (userId: string) => {

  const { showSnackbar } = useSnackbar();
  const { confirm } = useConfirm();
  const { getItem, setItem } = useLocalDb(LocalDbNames.LocalSettings, [LocalDbStores.Settings]);

  const requestPermission = async (): Promise<boolean> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop()); // Immediately stop the stream
      return true;
    } catch {
      return false;
    }
  }

  const getAudioInputDevices = async (): Promise<IAudioInputDevice[] | null> => {
    try {
      const hasPermission = await requestPermission();
      if (!hasPermission) {
        showSnackbar('Please grant access to your microphone in order to use this feature', 'warning');
        return null;
      }

      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(x => x.kind === 'audioinput' && x.deviceId.toLowerCase() !== 'default' && x.deviceId.toLowerCase() !== 'communications');

      const uniqueAudioDevices: IAudioInputDevice[] = [];
      for (const device of audioDevices) {
        if (uniqueAudioDevices.some(x => x.id === device.deviceId)) {
          continue;
        }
        uniqueAudioDevices.push({
          id: device.deviceId,
          label: device.label
        });
      }

      return uniqueAudioDevices;
    } catch {
      return [];
    }
  }

  const promptInputDeviceToUse = async (): Promise<IAudioInputDevice | null> => {
    try {
      const availableAudioDevices = await getAudioInputDevices();
      if (!availableAudioDevices) {
        return null;
      } else if (availableAudioDevices.length < 1) {
        showSnackbar('We could not detect any microphones on your current device. Please connect a microphone and retry.', 'warning');
        return null;
      }

      let localUserSettings = await getItem<ILocalSettings>(LocalDbStores.Settings, userId);
      if (!localUserSettings) {
        localUserSettings = { id: userId };
      }

      const result = await new Promise<string>((resolve) => {
        confirm({
          title: 'Select Microphone',
          message: 'Please select the microphone you would like to use',
          captureInputConfig: {
            label: 'Microphone',
            inputType: 'select',
            required: true,
            options: availableAudioDevices.map(x => ({
              value: x.id,
              text: x.label
            }))
          },
          onPositive: (result: string | undefined) => {
            resolve(result ?? '');
          }
        })
      });
      if (!result) {
        return null;
      } else {
        const matchingResult = availableAudioDevices.find(x => x.id === result);
        localUserSettings.audioInputDevice = {
          id: matchingResult!.id,
          label: matchingResult!.label
        };
      }
      
      await setItem(LocalDbStores.Settings, localUserSettings);

      const inputDeviceToUse = availableAudioDevices.find(x => x.id === localUserSettings.audioInputDevice?.id);
      return inputDeviceToUse ?? null;
    } catch (err: any) {
      logger.error('Error during useAudioRecorder::promptInputDeviceToUse', err);
      return null;
    }
  }

  const getInputDeviceToUse = async (overrideDeviceId?: string): Promise<IAudioInputDevice | null> => {
    try {
      const availableAudioDevices = await getAudioInputDevices();
      if (!availableAudioDevices) {
        return null;
      } else if (availableAudioDevices.length < 1) {
        showSnackbar('We could not detect any microphones on your current device. Please connect a microphone and retry.', 'warning');
        return null;
      }

      let localUserSettings = await getItem<ILocalSettings>(LocalDbStores.Settings, userId);
      if (!localUserSettings) {
        localUserSettings = { id: userId };
      }

      let deviceIdToUse = overrideDeviceId ?? localUserSettings.audioInputDevice?.id ?? '';
      if (!deviceIdToUse || !availableAudioDevices.some(x => x.id === deviceIdToUse)) {
        if (availableAudioDevices.length === 1) {
          deviceIdToUse = availableAudioDevices[0].id;
        } else {
          const result = await promptInputDeviceToUse();
          if (!result) {
            return null;
          }
          deviceIdToUse = result.id;
        }
      }

      const matchingAudioDevice = availableAudioDevices.find(x => x.id === deviceIdToUse);
      localUserSettings.audioInputDevice = !!matchingAudioDevice ? {
        id: matchingAudioDevice.id,
        label: matchingAudioDevice.label
      } : undefined;
      await setItem(LocalDbStores.Settings, localUserSettings);

      const inputDeviceToUse = availableAudioDevices.find(x => x.id === deviceIdToUse);
      return inputDeviceToUse ?? null;
    } catch (err: any) {
      logger.error('Error during useAudioRecorder::getDeviceIdToUse', err);
      return null;
    }
  }

  const recordDeviceAudio = async (options: IRecordDeviceAudioOptions): Promise<IAudioRecorderRef | null> => {
    try {
      const inputDeviceToUse = await getInputDeviceToUse(options.inputDeviceId);
      if (!inputDeviceToUse) {
        return null;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: inputDeviceToUse.id
        }
      });

      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      let audioChunks: Blob[] = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      let wasCancelled = false;
      let isFlushed = false;

      const cancelRecording = () => {
        wasCancelled = true;
        mediaRecorder.onstop = null;
        mediaRecorder.stop();
        stream.getTracks().forEach(track => track.stop());
      }

      const stopAndFlushRecording = () => {
        if (wasCancelled || isFlushed) {
          return Promise.resolve(null);
        }
        return new Promise<IDeviceAudio | null>((resolve) => {
          mediaRecorder.onstop = async () => {

            try {
              const webmBlob = new Blob(audioChunks, { type: 'audio/webm' });
              const mp3Blob = await convertWebmToMp3(webmBlob);
    
              let cleanedFileName = getSafeFileName(options.fileName);
              if (!cleanedFileName.endsWith('.mp3')) {
                cleanedFileName += '.mp3';
              }
              
              const dataUrl = URL.createObjectURL(mp3Blob);
              const webFile = new File([mp3Blob], cleanedFileName, { type: 'audio/mpeg' });
              const durationSeconds = await getAudioFileDuration(mp3Blob);
              resolve({
                dataUrl,
                file: webFile,
                durationSeconds
              });
            } catch (err) {
              if (options.onError) {
                options.onError(err);
              }
            }

            if (options.onRecordingEnded) {
              options.onRecordingEnded();
            }
          };

          mediaRecorder.stop();

          stream.getTracks().forEach(track => track.stop());

          isFlushed = true;
        });
      }

      mediaRecorder.onerror = (err) => {
        if (options.onError) {
          options.onError(err);
        }
      };

      mediaRecorder.onstart = () => {
        if (options.onRecordingStarted) {
          options.onRecordingStarted();
        }
      }
      mediaRecorder.start();


      return {
        stopAndFlushRecording,
        cancelRecording,
        activeInputDevice: inputDeviceToUse
      }

    } catch (err: any) {
      logger.error('Error during useAudioRecorder::recordDeviceAudio', err);
      if (options.onError) {
        options.onError(err);
      }
      return null;
    }
  }

  return {
    recordDeviceAudio,
    getAudioInputDevices,
    promptInputDeviceToUse
  }
}