import React, { useEffect, useState } from 'react';

import {
    InputAdornment,
    Typography,
    Autocomplete,
    AutocompleteProps,
    TextField
} from '@mui/material';

import { IMenuItem } from '@cw/models/shared';
import { useFormContext } from './Form.Context';
import { ProgressSpinner } from '../ProgressSpinner';
import { camelCaseToUserText } from '@cw/utils';
import { Clear } from '@mui/icons-material';

export interface IFormAutoCompleteProps<T> extends Omit<AutocompleteProps<IMenuItem, false, false, false>, 'label' | 'name' | 'options' | 'loading' | 'renderInput'> {
    name: keyof T;
    options: IMenuItem[];
    onSearchTextChanged: (searchText: string) => void;
    label?: string;
    required?: boolean;

    startIcon?: JSX.Element;
    isLoading?: boolean;
}
export function FormAutoComplete<T>(props: IFormAutoCompleteProps<T>) {

    const {
        label,
        name,
        options,
        onSearchTextChanged,
        startIcon,
        isLoading,
        required,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        customFormErrors,
        submitAttempted
    } = useFormContext<T>();

    const [searchText, setSearchText] = useState<string>('');

    const handleSelectionChange = (newValue: IMenuItem | null) => {
      setSearchText(newValue?.text ?? newValue?.value ?? '');
      handleFormChange(name, newValue);
    };

    const getIcon = (icon: JSX.Element) => {
        const color = submitAttempted && (formErrors[name] || !!customFormErrors[name]) ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(startIcon)}
        </InputAdornment>
    );

    const buildEndIcon = () => !isLoading ? null : (
        <InputAdornment position='end'>
            <ProgressSpinner size={20} />
        </InputAdornment>
    );

    useEffect(() => {
      const value = formData[name] as IMenuItem | null;
      const updatedSearchTextValue = value?.text ?? value?.value ?? '';
      if (updatedSearchTextValue !== searchText) {
        setSearchText(value?.text ?? value?.value ?? '');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, name]);

    return (
      <Autocomplete
        {...baseInputProps}
        options={options}
        isOptionEqualToValue={(option: IMenuItem, value: IMenuItem) => (option?.value ?? option?.text ?? '') === (value?.value ?? value?.text ?? '')}
        getOptionLabel={(option: IMenuItem) => option?.text ?? ''}
        loading={isLoading}
        autoHighlight
        inputValue={searchText}
        value={formData[name] as any}
        onChange={(_, newValue) => handleSelectionChange(newValue)}
        clearIcon={<Clear />}
        disabled={props.disabled || formDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              onSearchTextChanged(e.target.value);
            }}
            label={label ?? camelCaseToUserText(String(name))}
            InputProps={{
                ...params.InputProps,
                readOnly: props.readOnly,
                endAdornment: buildEndIcon(),
                startAdornment: buildStartIcon()
            }}
            required={required}
            name={String(name)}
            error={submitAttempted && (formErrors[name] || !!customFormErrors[name])}
            helperText={submitAttempted && !!customFormErrors[name] && (
              <Typography variant='caption' color='error'>{customFormErrors[name]}</Typography>
            )}
          />
        )}  
      />
    );
};
