import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';

import { useFormContext } from './Form.Context';

import { Dayjs } from 'dayjs';
import { camelCaseToUserText } from '@cw/utils';
import { Typography } from '@mui/material';

type TDatePickerValue = Dayjs | null | undefined;

interface IFormDateTimeInputProps<T> extends Omit<DateTimePickerProps<Dayjs>, 'name' | 'label' | 'disabled'> {
    name: keyof T;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    modifier?: 'startOfDay' | 'endOfDay' | 'startOfMinute' | 'endOfMinute';
    helperText?: string;
}
export function FormDateTimeInput<T>(props: IFormDateTimeInputProps<T>) {

    const {
        name,
        label,
        disabled,
        required,
        modifier,
        helperText,
        ...datePickerProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        customFormErrors,
        submitAttempted
    } = useFormContext<T>();

    const handleInputChange = (value: TDatePickerValue) => {
        let newValue = value;
        if (newValue && modifier === 'startOfDay') {
            newValue = newValue.startOf('day');
        } else if (newValue && modifier === 'endOfDay') {
            newValue = newValue.endOf('day');
        } else if (newValue && modifier === 'startOfMinute') {
            newValue = newValue.startOf('minute');
        } else if (newValue && modifier === 'endOfMinute') {
            newValue = newValue.endOf('minute');
        }

        handleFormChange(name, newValue);
    };

    return (
        <DateTimePicker
            {...datePickerProps}
            label={label ?? camelCaseToUserText(String(name))}
            format={props.format ?? 'DD MMM YYYY - hh:mm a'}
            value={formData[name] as TDatePickerValue}
            onChange={handleInputChange}
            slotProps={{
                textField: {
                    error: submitAttempted && (formErrors[name] || !!customFormErrors[name]),
                    required: required,
                    helperText: submitAttempted && !!customFormErrors[name] ? (
                        <Typography variant='caption' color='error'>{customFormErrors[name]}</Typography>
                    ) : helperText ? (
                        <Typography variant='caption'>{helperText}</Typography>
                    ) : null
                }
            }}
            disabled={disabled || formDisabled}
        />
    );
};