import { FunctionComponent, useMemo } from 'react';
import './styles/ErrorPage.scss';
import { Helmet } from 'react-helmet';

import { Stack,Container, Unstable_Grid2 as Grid, Typography } from '@mui/material';

import { useAppSettings, useAuth, useScreenSize } from '@cw/hooks';
import { classNames } from '@cw/utils';
import { Card, CardHeader, CardContent, Button } from '@cw/components/core';
import { buildSupportEmail } from '@cw/utils/supportUtils';

export const ErrorPage: FunctionComponent = () => {

  const { user } = useAuth();
  const { appSettings } = useAppSettings();
  const { isMobile, isSmallDesktop } = useScreenSize();

  const supportEmailDetails = useMemo(() => buildSupportEmail(appSettings, user), [appSettings, user]);

  const handleAppRestart = () => {
    window.location.reload();
  }

  return (
    <Stack
      flexDirection="row"
      justifyContent="center"
      className={classNames('cw-error-page', {
        mobile: isMobile,
        'small-desktop': isSmallDesktop
      })}
    >
      <Helmet>
        <title>Comp World | Something went wrong</title>
      </Helmet>

      <Container maxWidth="sm">
        <Grid xs={12} mb={5}>
          <img
            src="/images/logo-white.webp"
            alt="Comp World Logo"
            className={classNames('img logo', {
              mobile: isMobile
            })}
          />
        </Grid>
        <Grid xs={12}>
          <Card>
            <CardHeader title='Something went wrong' titleTypographyProps={{ textAlign: 'center', variant: 'h4' }} />
            <CardContent>
              <Stack rowGap={4}>
                <Typography textAlign='center'>
                  We are very sorry for this inconvenience, but our team has been notified and we are working to fix this in the background
                </Typography>

                <Stack rowGap={2}>
                  <Button onClick={handleAppRestart}>
                    Click here to reload
                  </Button>

                  <Typography variant='caption' textAlign='center'>
                    If the issue persists, please contact us on by <a className='cw-link' href={supportEmailDetails.href}>clicking here</a>
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Stack>
  )
}