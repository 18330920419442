import { FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-nz';

import {
  AppSettingsContextProvider,
  HttpClientContextProvider,
  SnackbarContextProvider,
  ConfirmContextProvider,
  FirebaseContextProvider,
  StripeContextProvider,
  ClockContextProvider,
  ScreenSizeContextProvider
} from '@cw/contexts';

import { QUERY_CLIENT_STALE_TIME } from '@cw/constants';

import AppTheme from './App.theme';
import AppRouter from './App.router';
import { initializePromptEvent } from '@cw/services/pwaInstallerService';

initializePromptEvent();

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: QUERY_CLIENT_STALE_TIME
    },
  }
});

const App: FunctionComponent = () => {
  return (
    <AppTheme>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-nz'>
        <SnackbarContextProvider>
          <QueryClientProvider client={QUERY_CLIENT}>
            <AppSettingsContextProvider>
              <FirebaseContextProvider>
                <HttpClientContextProvider>
                  <ScreenSizeContextProvider>
                    <ConfirmContextProvider>
                      <StripeContextProvider>
                        <ClockContextProvider>

                          <AppRouter />

                        </ClockContextProvider>
                      </StripeContextProvider>
                    </ConfirmContextProvider>
                  </ScreenSizeContextProvider>
                </HttpClientContextProvider>
              </FirebaseContextProvider>
            </AppSettingsContextProvider>
          </QueryClientProvider>
        </SnackbarContextProvider>
      </LocalizationProvider>
    </AppTheme>
  );
}

export default App;
