import { FunctionComponent } from 'react';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  Stack as MuiStack
} from '@mui/material';

import { INoDataContent } from './models';
import { Button } from '../Button';
import { Add } from '@mui/icons-material';

interface INoDataRowProps {
    columnCount: number;
    noDataContent?: INoDataContent;
}
export const NoDataTableRow: FunctionComponent<INoDataRowProps> = (props: INoDataRowProps) => {

    const {
        columnCount,
        noDataContent
    } = props;

    return (
        <MuiTableRow>
            <MuiTableCell
                colSpan={columnCount}
                align='center'
                style={{
                    borderBottom: 'none',
                    padding: '50px 0px'
                }}
            >
                {!!noDataContent ? (
                    <MuiStack justifyContent='center' alignItems='center' rowGap={2}>
                        <MuiTypography fontStyle='italic'>
                            {noDataContent.text}
                        </MuiTypography>
                        {noDataContent.createCtaText && (
                            <Button
                                size='small'
                                variant='outlined'
                                startIcon={<Add />}
                                onClick={noDataContent.createCtaClicked}
                            >
                                {noDataContent.createCtaText}          
                            </Button>
                        )}
                    </MuiStack>
                ) : (
                    <MuiTypography>
                        <i>No data available...</i>
                    </MuiTypography>
                )}
            </MuiTableCell>
        </MuiTableRow>
    )
};