import { AUDIO_MIME_TYPES, AUDIO_EXTENSIONS, AUDIO_TYPE } from '@cw/constants';

export const getAudioFileMimeType = (fileName: string, mimeType: string): string => {

  let matchingMimeType = '';
  Object.values(AUDIO_MIME_TYPES).forEach(x => {
    if (x.includes(mimeType.toLowerCase())) {
      matchingMimeType = mimeType.toLowerCase();
    }
  });

  if (matchingMimeType) {
    return matchingMimeType;
  }

  const fileNameParts = fileName.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

  let matchingAudioType: AUDIO_TYPE | null = null;
  for (const type in AUDIO_EXTENSIONS) {
    const audioType = type as AUDIO_TYPE;
    if (AUDIO_EXTENSIONS[audioType] === fileExtension) {
      matchingAudioType = audioType;
      break;
    }
  }

  if (matchingAudioType) {
    return AUDIO_MIME_TYPES[matchingAudioType][0];
  }

  return mimeType.toLowerCase();
}