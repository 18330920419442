import React from 'react';
import './styles/DataTableRow.scss';

import { Link as RouterLink } from 'react-router-dom';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Stack as MuiStack,
  Checkbox
} from '@mui/material';

import { TableColumn } from './models';
import { camelCaseToUserText, formatAsDisplayType, getColor } from '@cw/utils';
import { IconButton } from '../IconButton';
import { ContentCopy } from '@mui/icons-material';
import { useSnackbar } from '@cw/hooks';

interface IDataTableRowProps<T> {
  row: T;
  columns: TableColumn<T>[];
  getRowClickRoute?: (row: T) => string;
  onRowClick?: (row: T) => void;

  checkboxSelection?: boolean;
  checkboxChecked?: boolean;
  checkboxDisabled?: boolean;
  checkboxSelectionChanged?: () => void;
}
export function DataTableRow<T>(props: IDataTableRowProps<T>) {
  const {
    row,
    columns,
    getRowClickRoute,
    onRowClick,
    checkboxSelection,
    checkboxChecked,
    checkboxDisabled,
    checkboxSelectionChanged
  } = props;

  const { showSnackbar } = useSnackbar();

  const getColumnLabel = (column: TableColumn<T>): string => {
    return column.label || camelCaseToUserText(column.key as string);
  };

  const getRowColumnValue = (
    row: T,
    column: TableColumn<T>
  ): React.ReactNode => {
    if (column.element) {
      return column.element(row);
    } else if (!column.key) {
      return '';
    }

    const columnValue = row[column.key] as any;
    if (
      columnValue === '' ||
      columnValue === null ||
      columnValue === undefined
    ) {
      return '';
    } else if (column.displayType) {
      return formatAsDisplayType(columnValue, column.displayType);
    }

    return columnValue as any;
  };

  const wrapCellContent = (column: TableColumn<T>, cell: React.ReactNode): React.ReactNode => getRowClickRoute && !column.disableRowClick ? (
    <RouterLink
      to={getRowClickRoute(row)}
      className='cw-data-table-row-wrapper'
    >
      {cell}
    </RouterLink>
  ) : (
    <div className='cw-data-table-row-wrapper' onClick={() => {
      if (onRowClick && !column.disableRowClick) {
        onRowClick(row);
      } else if (checkboxSelection && checkboxSelectionChanged && !checkboxDisabled && !column.disableRowClick) {
        checkboxSelectionChanged();
      }
    }}>
      {cell}
    </div>
  );

  return (
    <React.Fragment>
      <MuiTableRow hover sx={{ cursor: getRowClickRoute || onRowClick || checkboxSelection ? 'pointer' : undefined }}>
        {checkboxSelection && (
          <MuiTableCell
            sx={{
              position: 'relative',
              padding: 0
            }}
          >
            <Checkbox
              checked={checkboxChecked}
              onChange={checkboxSelectionChanged}
              disabled={checkboxDisabled}
            />
          </MuiTableCell>
        )}
        {columns.map((column, index) => (
          <MuiTableCell
            key={index}
            sx={{
              position: 'relative',
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              whiteSpace: 'normal',
              color: column.textColor ? getColor(column.textColor) : undefined,
              padding: 0
            }}
          >
            {wrapCellContent(column, (
              <MuiStack direction='row' alignItems='center' columnGap={1}>
                  {getRowColumnValue(row, column)}
                  {!!column.canCopy && !!column.key && (
                      <IconButton
                          icon={<ContentCopy fontSize='small' />}
                          size='small'
                          tooltip='Copy'
                          onClick={() => {
                              navigator.clipboard.writeText(row[column.key!] ? (row[column.key!] as any).toString() : '').then(() => {
                                  showSnackbar(`${getColumnLabel(column)} copied to clipboard`);
                              })
                          }}
                      />
                  )}
              </MuiStack>
            ))}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </React.Fragment>
  );
}
