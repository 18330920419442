const numberFormatter = new Intl.NumberFormat('en-NZ');

const nzdCurrencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD'
});

export const formatCurrency = (amount: number): string => {
  return nzdCurrencyFormatter.format(amount ?? 0);
}

export const formatNumber = (amount: number): string => 
  numberFormatter.format(amount);