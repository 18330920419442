import React, { useState } from 'react';

import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    SelectProps,
    MenuItem,
    InputAdornment,
    SelectChangeEvent,
    ListItemIcon,
    ListItemText,
    Divider,
    Typography,
    Stack
} from '@mui/material';

import { IMenuItem } from '@cw/models/shared';
import { useFormContext } from './Form.Context';
import { ProgressSpinner } from '../ProgressSpinner';
import { camelCaseToUserText } from '@cw/utils';
import { Check } from '@mui/icons-material';

export interface IFormSelectInputProps<T> extends Omit<SelectProps, 'label' | 'name'> {
    name: keyof T;
    options: IMenuItem[];
    label?: string;

    startIcon?: JSX.Element;
    isLoading?: boolean;
    helperText?: string;
    multipleCountOnly?: boolean;
}
export function FormSelectInput<T>(props: IFormSelectInputProps<T>) {

    const {
        label,
        name,
        options,
        startIcon,
        isLoading,
        sx,
        helperText,
        multipleCountOnly,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        customFormErrors,
        submitAttempted
    } = useFormContext<T>();

    const [hasFocus, setHasFocus] = useState(false);

    const handleSelectionChange = (e: SelectChangeEvent<unknown>) => {
        const newValue = e.target.value;
        handleFormChange(name, newValue);
    };

    const getIcon = (icon: JSX.Element) => {
        const color = props.error ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(startIcon)}
        </InputAdornment>
    );

    const buildEndIcon = () => !isLoading ? null : (
        <InputAdornment position='end'>
            <ProgressSpinner size={20} />
        </InputAdornment>
    );

    return (
        <FormControl
            fullWidth
            error={submitAttempted && (formErrors[name] || !!customFormErrors[name])}
            required={props.required}
            margin={props.margin}
            sx={sx}
        >
            <InputLabel
                id={String(name)}
                size={props.size === 'small' ? 'small' : 'normal'}
                shrink={(baseInputProps.multiple ? ((formData[name] ?? []) as any[]).length > 0 : !!formData[name]) || hasFocus || !!startIcon}
            >
                {label ?? camelCaseToUserText(String(name))}
            </InputLabel>
            <MuiSelect
                {...baseInputProps}
                labelId={String(name)}
                value={formData[name]}
                label={label ?? camelCaseToUserText(String(name))}
                onChange={handleSelectionChange}
                error={submitAttempted && (formErrors[name] || !!customFormErrors[name])}
                inputProps={{
                    helperText: submitAttempted && !!customFormErrors[name] ? (
                        <Typography variant='caption' color='error'>{customFormErrors[name]}</Typography>
                    ) : helperText,
                }}
                disabled={props.disabled || formDisabled || isLoading}
                name={String(name)}
                startAdornment={buildStartIcon()}
                endAdornment={buildEndIcon()}
                renderValue={(selected: any) => {
                    if (Array.isArray(selected)) {
                        if (multipleCountOnly) {
                            return `${selected.length} selected`;
                        } else {
                            const matchingItems = selected
                                .map(x => options.find(y => x === (y.value ?? y.text)))
                                .map(x => x?.text);
                            return matchingItems.join(', ');
                        }
                    } else {
                        const matchingItem = options!.find(x => (x.value ?? x.text) === selected)
                        return matchingItem?.text ?? matchingItem?.content;
                    }
                }}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
            >
                {
                    options!.filter(x => !x.hidden).map((option, index) => option.isDivider ? (
                        <Divider key={index} />
                    ) : (
                        <MenuItem
                            value={option.value ?? option.text}
                            key={option.value ?? option.text ?? index}
                            disabled={option.disabled}
                        >
                            <Stack direction='row' alignItems='center' columnGap={1} flex={1}>
                                {
                                    option.icon && (
                                        <ListItemIcon>
                                            {option.icon}
                                        </ListItemIcon>
                                    )
                                }
                                {option.content ?? (
                                    <ListItemText>{option.text}</ListItemText>
                                )}
                                
                                {baseInputProps.multiple && ((formData[name] ?? []) as any[]).includes(option.value ?? option.text) && (
                                    <ListItemIcon>
                                        <Check color='primary' />
                                    </ListItemIcon>
                                )}
                            </Stack>
                        </MenuItem>
                    ))
                }
            </MuiSelect>
        </FormControl>
    )
};
