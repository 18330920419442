import { FunctionComponent, useMemo } from 'react';
import './styles/SocialsButton.scss';

import { classNames } from '@cw/utils';
import { TFirebaseAuthProvider } from '@cw/models/firebase';
import { ProgressSpinner } from './ProgressSpinner';

interface ISocialsButtonProps {
  provider: TFirebaseAuthProvider;
  onClick: (provider: TFirebaseAuthProvider) => void;
  disabled?: boolean;
  loading?: boolean;
}
export const SocialsButton: FunctionComponent<ISocialsButtonProps> = (props: ISocialsButtonProps) => {

  const {
    provider,
    onClick,
    disabled,
    loading
  } = props;

  const providerName = useMemo(() => provider.substring(0, 1).toUpperCase() + provider.substring(1).toLowerCase(), [provider]);

  return (
    <div
      className={classNames('cw-socials-button', provider, {
        disabled: (disabled ?? false) || (loading ?? false)
      })}
      onClick={() => disabled ? null : onClick(provider)}
    >
      <img src={`/images/${provider}-logo.webp`} alt={provider} />
      Continue with {providerName}

      {loading && (
        <ProgressSpinner color={provider === 'google' ? 'dark' : 'light'} size={20} />
      )}
    </div>
  )
}