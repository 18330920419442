import { TOnTheDayLinkType, IOnTheDaySongDownloadLink } from "./onTheDay";

export enum LocalDbStores {
  CompetitionSongsMetaData = 'competitionSongsMetaData',
  CompetitionSongs = 'competitionSongs',
  OnTheDayAuthDetails = 'onTheDayAuthDetails',
  Settings = 'settings'
}

export enum LocalDbNames {
  CompetitionSongs = 'cwWebPortalCompetitionSongsLocalStorageDb',
  OnTheDay = 'cwWebPortalOnTheDayLocalStorageDb',
  LocalSettings = 'cwWebPortalSettingsStorageDb',
}

export interface ILocalDbBase {
  id: string;
}

export interface ILocalSettings extends ILocalDbBase {
  audioInputDevice?: {
    id: string;
    label: string;
  };
  showCompletedCompetitions?: boolean;
}

export interface IDownloadedCompetitionSongMetaData extends ILocalDbBase, IOnTheDaySongDownloadLink {}
export interface IDownloadedCompetitionSong extends ILocalDbBase {
  songBlobBase64: string;  
}

export interface IOnTheDayUserAuthDetails extends ILocalDbBase {
  accessToken: string;
  linkType: TOnTheDayLinkType;
}