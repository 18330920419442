import { useState } from "react"
import { logger } from "@cw/services/logger";

export const useFileUploader = () => {

  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const uploadFile = (url: string, file: File, headers?: Record<string, string>): Promise<boolean> => {
    return new Promise((resolve) => {
      setIsUploadingFile(true);
      setFileUploadProgress(0);

      try {  
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);

        xhr.setRequestHeader('Content-Type', file.type);
        if (headers) {
          Object.keys(headers).forEach(key => {
            xhr.setRequestHeader(key, headers[key]);
          })
        }
  
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setFileUploadProgress(percentCompleted);
          }
        };
  
        xhr.onload = () => {
          setIsUploadingFile(false);
          setFileUploadProgress(100);

          const isSuccessStatus = xhr.status >= 200 && xhr.status < 300;
          if (!isSuccessStatus) {
            logger.error(`Failed to upload file to [${url}]`, {
              status: xhr.status,
              statusText: xhr.statusText,
              requestHeaders: headers,
              responseHeaders: xhr.getAllResponseHeaders()
            });
          }

          resolve(isSuccessStatus);
        };
  
        xhr.onerror = () => {
          setIsUploadingFile(false);
          logger.error(`Failed to upload file to [${url}]`, {
            status: xhr.status,
            statusText: xhr.statusText,
            requestHeaders: headers,
            responseHeaders: xhr.getAllResponseHeaders()
          });
          resolve(false);
        };
  
        xhr.send(file);
      } catch (err) {
        setIsUploadingFile(false);
        logger.error(`Error during useFileUploader::uploadFile to [${url}]`, err);
        resolve(false);
      }
    });
  }

  return {
    uploadFile,
    fileUploadProgress,
    isUploadingFile
  }
}