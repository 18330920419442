import { FunctionComponent } from 'react';

import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { TColor } from '@cw/models/shared';

interface IInfoTooltipProps {
  tooltip: string;
  color?: TColor;
}
export const InfoTooltip: FunctionComponent<IInfoTooltipProps> = (props: IInfoTooltipProps) => {

  const {
    tooltip,
    color
  } = props;

  if (!tooltip) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <Info color={color ?? 'primary'} sx={{ fontSize: 'var(--cw-spacing-5)', opacity: 0.8 }} />
    </Tooltip>
  )
}