import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import App from './App';
import { logger } from './services/logger';
import { HttpClientError } from './models/shared';

window.buildVersion = process.env.REACT_APP_BUILD_VERSION ?? '0.0.0';

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  event.preventDefault();
  if (!(event.reason instanceof HttpClientError)) {
    logger.error('Global Unhandled Promise Rejection Error', event);
  }
  return true;
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
