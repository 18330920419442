import { FunctionComponent } from 'react';
import { createTheme, PaletteColorOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import palette from './palette.module.scss';

const defaultFontFamily = [
    '"Poppins"'
].join(',');
const headerFontFamily = [
    '"Roboto"'
].join(',');

interface IAppThemeProps {
    children: any;
};
const AppTheme: FunctionComponent<IAppThemeProps> = (props: IAppThemeProps) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: palette.colorPrimary,
                contrastText: '#fff',
            },
            secondary: {
                main: palette.colorSecondary,
                contrastText: '#fff'
            },
            background: {
                default: palette.colorBackground,
                paper: palette.colorBackgroundLighter
            },
            light: {
                main: palette.colorLight
            },
            dark: {
                main: palette.colorDark
            },
            tertiary: {
                main: palette.colorTertiary,
                contrastText: '#fff'
            },
            text: {
                primary: palette.colorText,
                disabled: palette.colorTextDisabled
            },
            textDisabled: {
                main: palette.colorTextDisabled,
                contrastText: '#fff'
            },
            error: {
                main: palette.colorError
            },
            success: {
                main: palette.colorSuccess
            },
            warning: {
                main: palette.colorWarning,
                contrastText: '#fff'
            }
        },
        typography: {
            fontFamily: defaultFontFamily,
            h1: {
                fontFamily: headerFontFamily
            },
            h2: {
                fontFamily: headerFontFamily
            },
            h3: {
                fontFamily: headerFontFamily
            },
            h4: {
                fontFamily: headerFontFamily
            },
            h5: {
                fontFamily: headerFontFamily
            },
            h6: {
                fontFamily: headerFontFamily
            },
            button: {
                fontFamily: defaultFontFamily,
                fontWeight: '700',
                fontSize: '15px',
                lineHeight: '20px',
                textTransform: 'none'
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    ':root': {
                        '--color-primary': palette.colorPrimary,
                        '--color-primary-rgb': palette.colorPrimaryRGB,
                        '--color-background': palette.colorBackground,
                        '--color-background-rgb': palette.colorBackgroundRGB,
                        '--color-light': palette.colorLight,
                        '--color-light-rgb': palette.colorLightRGB,
                        '--color-dark': palette.colorDark,
                        '--color-dark-rgb': palette.colorDarkRGB,
                        '--color-background-lighter': palette.colorBackgroundLighter,
                        '--color-background-lighter-rgb': palette.colorBackgroundLighterRGB,
                        '--color-background-darker': palette.colorBackgroundDarker,
                        '--color-background-darker-rgb': palette.colorBackgroundDarkerRGB,
                        '--color-text': palette.colorText,
                        '--color-text-rgb': palette.colorTextRGB,
                        '--color-text-disabled': palette.colorTextDisabled,
                        '--color-text-disabled-rgb': palette.colorTextDisabledRGB,
                        '--color-secondary': palette.colorSecondary,
                        '--color-secondary-rgb': palette.colorSecondaryRGB,
                        '--color-tertiary': palette.colorTertiary,
                        '--color-tertiary-rgb': palette.colorTertiaryRGB,
                        '--color-error': palette.colorError,
                        '--color-error-rgb': palette.colorErrorRGB,
                        '--color-success': palette.colorSuccess,
                        '--color-success-rgb': palette.colorSuccessRGB,
                        '--color-warning': palette.colorWarning,
                        '--color-warning-rgb': palette.colorWarningRGB
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: 'var(--color-dark)',
                        fontSize: 'var(--cw-spacing-3)',
                        padding: 'var(--cw-spacing-2) var(--cw-spacing-3)',
                        whiteSpace: 'break-spaces'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        padding: '18px 24px',
                        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-weight 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: 'inherit'
                    }
                },
                defaultProps: {
                    variant: 'contained'
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            padding: '10px 15px'
                        }
                    },
                    {
                        props: { size: 'large' },
                        style: {
                            fontWeight: '800',
                            fontSize: '18px'
                        }
                    }
                ]
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: 'var(--cw-box-shadow)'
                    }
                },
                variants: [
                    {
                        props: { color: 'primary' },
                        style: { backgroundColor: 'var(--color-primary)' }
                    },
                    {
                        props: { color: 'secondary' },
                        style: { backgroundColor: 'var(--color-secondary)' }
                    },
                    {
                        props: { color: 'success' },
                        style: { backgroundColor: 'var(--color-success)' }
                    },
                    {
                        props: { color: 'warning' },
                        style: { backgroundColor: 'var(--color-warning)' }
                    },
                    {
                        props: { color: 'error' },
                        style: { backgroundColor: 'var(--color-error)' }
                    },
                    {
                        props: { color: 'tertiary' },
                        style: { backgroundColor: 'var(--color-tertiary)' }
                    }
                ]
            },
            MuiCardHeader: {
                defaultProps: {
                    titleTypographyProps: {
                        textAlign: 'left',
                        variant: 'h5'
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px !important',
                        padding: '20px'
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '0px 16px'
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: '8px 16px 16px 16px'
                    }
                }
            },
            MuiAccordionActions: {
                styleOverrides: {
                    root: {
                        padding: '8px 36px'
                    }
                }
            },
            MuiCardActions: {
                defaultProps: {
                    sx: {
                        justifyContent: 'flex-end',
                        columnGap: 2
                    }
                },
                styleOverrides: {
                    root: {
                        padding: '16px'
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    fullWidth: true,
                    margin: 'normal'
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px'
                    }
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px'
                    }
                },
                variants: [
                    {
                        props: { size: 'small' },
                        style: {
                            fontSize: '14px'
                        }
                    }
                ]
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        boxSizing: 'border-box'
                    },
                    message: {
                        flex: '1'
                    },
                    icon: {
                        color: 'inherit !important'
                    }
                },
                variants: [
                    { props: { severity: 'error' }, style: { backgroundColor: 'var(--color-error)', color: 'var(--color-light)' } },
                    { props: { severity: 'warning' }, style: { backgroundColor: 'var(--color-warning)', color: 'var(--color-light)' } },
                    { props: { severity: 'success' }, style: { backgroundColor: 'var(--color-success)', color: 'var(--color-light)' } },
                ]
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        flex: '1',
                        boxShadow: 'var(--cw-box-shadow)'
                    }
                }
            },
            MuiFormControl: {
                defaultProps: {
                    margin: 'normal'
                }
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        maxHeight: '400px'
                    },
                    paper: {
                        maxHeight: '400px'
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        padding: '12px 30px'
                    }
                }
            },
            MuiSnackbar: {
                defaultProps: {
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap'
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '20px 24px'
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        margin: '8px 0px 0px 0px'
                    }
                }
            },
            MuiStack: {
                defaultProps: {
                    spacing: 0,
                },
                variants: [
                    {
                        props: { direction: 'column' },
                        style: { maxWidth: '100%' }
                    }
                ]
            },
            MuiTypography: {
                variants: [
                    {
                        props: { color: 'success' },
                        style: { color: 'var(--color-success)' }
                    },
                    {
                        props: { color: 'warning' },
                        style: { color: 'var(--color-warning)' }
                    },
                    {
                        props: { color: 'tertiary' },
                        style: { color: 'var(--color-tertiary)' }
                    },
                    {
                        props: { color: 'error' },
                        style: { color: 'var(--color-error)' }
                    },
                    {
                        props: { color: 'light' },
                        style: { color: 'var(--color-light)' }
                    },
                    {
                        props: { color: 'text.disabled' },
                        style: { color: 'var(--color-text-disabled)' }
                    },
                    {
                        props: { variant: 'caption' },
                        style: { color: 'var(--color-text-disabled)', fontStyle: 'italic' }
                    },
                    {
                        props: { variant: 'body3' },
                        style: { fontSize: '0.75rem', lineHeight: '1.3' }
                    }
                ]
            },
            MuiStepConnector: {
                styleOverrides: {
                    root: {
                        top: '23px'
                    },
                    line: {
                        borderTopWidth: '4px'
                    }
                }
            },
            MuiBadge: {
                styleOverrides: {
                    badge: {
                        right: '-10px'
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    li: {
                        color: 'var(--color-text-disabled)'
                    }
                }
            },
            MuiLinearProgress: {
                defaultProps: {
                    variant: 'determinate'
                },
                styleOverrides: {
                    root: {
                        height: 10,
                        borderRadius: 6
                    }
                }
            },
            MuiTab: {
                defaultProps: {
                    iconPosition: 'start'
                }
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    )
};

declare module '@mui/material/styles' {
    interface CustomPalette {
        tertiary: PaletteColorOptions;
        light: PaletteColorOptions;
        dark: PaletteColorOptions;
        textDisabled: PaletteColorOptions
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
  }
  
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}
  
declare module '@mui/material/Badge' {
    interface BadgePropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}
  
declare module '@mui/material/CircularProgress' {
    interface CircularProgressPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        tertiary: true;
        light: true;
        dark: true;
        textDisabled: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
    }
}

export default AppTheme;