import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';

import { IMenuItem, IReducerAction } from '@cw/models/shared';
import { Button, Form, FormSelectInput, FormTextInput } from '@cw/components/core';

type TSnackbarActions = 'OPEN' | 'CLOSE';

interface IConfirmCaptureInputConfig {
    label: string;
    inputType?: 'text' | 'select';
    multiline?: boolean;
    rows?: number;
    required?: boolean;
    maxLength?: number;
    helperText?: string;
    options?: IMenuItem[];
}

interface IConfirmConfig {
    title?: string;
    message?: string;
    body?: React.ReactNode;
    onPositive?: (capturedInput?: string) => void | Promise<void>;
    positiveText?: string;
    positiveOnly?: boolean;
    onNegative?: () => void | Promise<void>;
    negativeText?: string;
    captureInputConfig?: IConfirmCaptureInputConfig;
}

interface IConfirmContext {
    confirm: (config: IConfirmConfig) => void;
    isOpen: boolean;
    config: IConfirmConfig | null;
}

const initialState: IConfirmContext = {
    confirm: () => null,
    isOpen: false,
    config: null
};
export const ConfirmContext = React.createContext<IConfirmContext>(initialState);

const ConfirmContextReducer = (state: IConfirmContext, action: IReducerAction<TSnackbarActions>): IConfirmContext => {
    switch (action.type) {
        case 'OPEN':
            return {...state, isOpen: true, config: action.payload};
        case 'CLOSE':
            return {...state, isOpen: false, config: null};
        default:
            return state;
    }
};

interface IConfirmProviderProps {
    children: any;
};
export const ConfirmContextProvider: FunctionComponent<IConfirmProviderProps> = (props: IConfirmProviderProps) => {
    const [state, dispatch] = useReducer(ConfirmContextReducer, {...initialState,
        confirm: (config: IConfirmConfig) => {
            dispatch({
                type: 'OPEN',
                payload: config
            });
        }
    });

    const [captureInputFormData, setCaptureInputFormData] = useState<{ input: string }>({
        input: ''
    });

    const handleNegativeClose = async () => {
        if (state.config?.onNegative) {
            await Promise.resolve(state.config.onNegative());
        }
        dispatch({ type: 'CLOSE' });
    }

    const handlePositive = async () => {
        if (state.config?.onPositive) {
            const capturedInput = !!state.config?.captureInputConfig ? captureInputFormData.input : undefined;
            await Promise.resolve(state.config.onPositive(capturedInput));
        }
        dispatch({type: 'CLOSE'});
    }

    useEffect(() => {
        setCaptureInputFormData({ input: '' });
    }, [state.isOpen]);

    return (
        <ConfirmContext.Provider value={state}>
            {props.children}

            <Dialog
                open={state.isOpen}
                onClose={handleNegativeClose}
            >
                <Form
                    formData={captureInputFormData}
                    formDataChanged={setCaptureInputFormData}
                    onSubmit={handlePositive}
                >
                    <DialogTitle>{state.config?.title ?? 'Are you sure?'}</DialogTitle>
                    <DialogContent>
                        {state.config?.message && (
                            <Typography variant='body2' sx={{
                                whiteSpace: 'break-spaces'
                            }}>
                                {state.config?.message}
                            </Typography>
                        )}

                        {state.config?.body}

                        {!!state.config?.captureInputConfig && (
                            <Stack marginTop={2}>
                                {state.config.captureInputConfig.inputType === 'select' ? (
                                    <FormSelectInput<{ input: string }>
                                        name='input'
                                        options={state.config.captureInputConfig.options ?? []}
                                        label={state.config.captureInputConfig.label}
                                        required={state.config.captureInputConfig.required}
                                        helperText={state.config.captureInputConfig.helperText}
                                    />
                                ) : (
                                    <FormTextInput<{ input: string }>
                                        name='input'
                                        label={state.config.captureInputConfig.label}
                                        multiline={state.config.captureInputConfig.multiline}
                                        minRows={state.config.captureInputConfig.rows}
                                        maxRows={state.config.captureInputConfig.rows}
                                        required={state.config.captureInputConfig.required}
                                        maxLength={state.config.captureInputConfig.maxLength}
                                        helperText={state.config.captureInputConfig.helperText}
                                    />
                                )}
                            </Stack>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {
                            !(state.config?.positiveOnly) && (
                                <Button
                                    variant='text'
                                    color='error'
                                    type='button'
                                    onClick={handleNegativeClose}
                                >
                                    {state.config?.negativeText ?? 'Cancel'}
                                </Button>
                            )
                        }

                        <Button
                            variant='text'
                            type={!!state.config?.captureInputConfig ? 'submit' : 'button'}
                            onClick={() => !state.config?.captureInputConfig ? handlePositive() : null}
                        >
                            {state.config?.positiveText ?? 'Continue'}
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </ConfirmContext.Provider>
    );
}