

export const RESIZED_IMAGE_MAX_WIDTH = 720;
export const RESIZED_IMAGE_MAX_HEIGHT = 1440;
export const RESIZED_IMAGE_QUALITY = 0.8;

export interface IDeviceImage {
  base64: string;
  dataUrl: string;
  fileName: string;
  contentType: string;
}

export const getDeviceImage = (): Promise<IDeviceImage | null> => {
  return new Promise<IDeviceImage | null>((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png';

    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      const file: File | null = target.files ? target.files[0] : null;
      
      if (file) {
        const reader = new FileReader();

        reader.onload = async () => {
          const dataUrl = reader.result as string;

          const resizedResult = await resizeAndLowerQuality(dataUrl, RESIZED_IMAGE_MAX_WIDTH, RESIZED_IMAGE_MAX_HEIGHT, RESIZED_IMAGE_QUALITY);
          if (resizedResult) {
            resolve({
              fileName: file.name,
              contentType: resizedResult?.contentType,
              base64: resizedResult?.base64,
              dataUrl: resizedResult?.dataUrl
            });
          } else {
            resolve({
              fileName: file.name,
              contentType: file.type,
              base64: dataUrl.split(',')[1],
              dataUrl: dataUrl
            });
          }
        };

        reader.onerror = () => {
          resolve(null);
        };

        reader.readAsDataURL(file);
      } else {
        resolve(null);
      }
    };

    input.click();
  });
}

export const resizeAndLowerQuality = (
  dataUrl: string,
  maxWidth: number,
  maxHeight: number,
  quality: number
): Promise<{ dataUrl: string, base64: string, contentType: string } | null> => {
  return new Promise<{ dataUrl: string, base64: string, contentType: string } | null>((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions while maintaining the aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas to a base64 string with the specified quality
      const newBase64 = canvas.toDataURL("image/jpeg", quality);
      resolve({
        dataUrl: newBase64,
        base64: newBase64.split(',')[1],
        contentType: "image/jpeg"
      });
    };
    img.onerror = (err) => {
      resolve(null);
    };

    // Set the image source to the base64 string
    img.src = dataUrl;
  });
};