import { useMutation, useQuery, useQueryClient } from "react-query";

import { HttpClientError, IQuerySettings, QueryKeys, getQuerySettings } from '@cw/models/shared';
import { IAuthenticateOnTheDayUserRequest, IAuthenticateOnTheDayUserResponse, IInitializeAuthRequest, IOnTheDayInitData } from '@cw/models/auth';
import { IUser } from '@cw/models/self';
import { useHttpClient, useLocalDb } from '@cw/hooks';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@cw/constants";
import { formatRoute } from "@cw/utils";
import { LocalDbNames, LocalDbStores } from "@cw/models/localDb";

const BASE_URL = '/v1/auth';

export const useInitializeAuthMutation = (settings?: IQuerySettings<IUser, IInitializeAuthRequest>) => {
    const { POST } = useHttpClient();
    const queryClient = useQueryClient();

    const initializeAuth = async (request: IInitializeAuthRequest) => {
        return POST<IUser>(`${BASE_URL}/initialize`, request);
    }

    const querySettings = getQuerySettings(settings)
    return useMutation<IUser, HttpClientError, IInitializeAuthRequest>(initializeAuth, {
        onSuccess: (response, input) => {
          queryClient.setQueryData([QueryKeys.Self], response);
          if (querySettings.onSuccess) {
            querySettings.onSuccess(response, input)
          }
        },
        onError: querySettings.onError
    })
}

export const useAuthenticateOnTheDayUserMutation = (settings?: IQuerySettings<IAuthenticateOnTheDayUserResponse, IAuthenticateOnTheDayUserRequest>) => {
    const { POST } = useHttpClient();

    const authenticateOnTheDayUser = async (request: IAuthenticateOnTheDayUserRequest) => {
        return POST<IAuthenticateOnTheDayUserResponse>(`${BASE_URL}/on-the-day`, request);
    }

    const querySettings = getQuerySettings(settings)
    return useMutation<IAuthenticateOnTheDayUserResponse, HttpClientError, IAuthenticateOnTheDayUserRequest>(authenticateOnTheDayUser, {
      ...querySettings
    })
}

export const useGetOnTheDayInitData = (linkId: string, settings?: IQuerySettings<IOnTheDayInitData>) => {
  const { GET } = useHttpClient();
  const navigate = useNavigate();
  const localDb = useLocalDb(LocalDbNames.OnTheDay, [LocalDbStores.OnTheDayAuthDetails]);

  const querySettings = getQuerySettings(settings);
  return useQuery<IOnTheDayInitData, HttpClientError>([QueryKeys.OnTheDayInitData, linkId], () => {
      return GET<IOnTheDayInitData>(`${BASE_URL}/on-the-day/${linkId}/initialize`);
  }, {
    ...querySettings,
    enabled: querySettings.enabled && Boolean(linkId),
    onError: (error) => {
      if (error.statusCode === 400 || error.statusCode === 404) {
        localDb.deleteItem(LocalDbStores.OnTheDayAuthDetails, linkId).finally(() => {
          navigate(formatRoute(ROUTES.OnTheDayLogin, {linkId}));
        });
      }
    }
  });
}