let promptInstallEvent: BeforeInstallPromptEvent | null;

export const initializePromptEvent = () => {
  window.addEventListener('beforeinstallprompt', (e: any) => {
    e.preventDefault();
    promptInstallEvent = e as any;
  });
}

export const isPWA = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone === true; // iOS Safari
}

export const getPWAInstallPromptEvent = () => promptInstallEvent;

export const promptPWAInstall = async (): Promise<boolean> => {
  if (promptInstallEvent) {
    await promptInstallEvent.prompt();
    const result = await promptInstallEvent.userChoice;
    return result.outcome === 'accepted';
  } else {
    return false;
  }
}