import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Alert, Stack, Typography, Breadcrumbs, Container } from '@mui/material';

import { DEFAULT_ERROR_MESSAGE } from '@cw/constants';
import { PageLoader } from '@cw/components';
import { useScreenSize } from '@cw/hooks';

export interface IBreadCrumbOption {
    text: string;
    routerLink?: string;
    active?: boolean;
}
interface IDashboardPageWrapperProps {
    title: React.ReactNode;
    pageTitle?: string;
    className?: string;
    titleActions?: React.ReactNode;
    isPageLoadError?: boolean;
    isLoading?: boolean;
    breadCrumbs?: IBreadCrumbOption[];
    size?: 'full' | 'restrained' | 'narrow';
    rowGap?: number;
    children: any;
}
export const DashboardPageWrapper: FunctionComponent<IDashboardPageWrapperProps> = (props: IDashboardPageWrapperProps) => {

    const { isMobile } = useScreenSize();

    const {
        title,
        pageTitle,
        className,
        children,
        titleActions,
        isPageLoadError,
        isLoading,
        breadCrumbs,
        rowGap,
        size
    } = props;

    return (
        <>
            <Helmet>
                <title>{pageTitle ?? title} | Comp World</title>
            </Helmet>
            
            <Container maxWidth={size === 'restrained' ? 'lg' : size === 'narrow' ? 'md' : false} className={className}>
                <Stack rowGap={3}>
                    <Stack rowGap={1}>
                        <Stack direction={isMobile ? 'column' : 'row'} rowGap={1} columnGap={3} alignItems={isMobile ? 'stretch' : 'center'}>
                            <Typography variant={isMobile ? 'h4' : 'h3'}>{title}</Typography>
                            <Stack direction={isMobile ? 'column' : 'row'} columnGap={3} rowGap={1} flex={1} justifyContent='flex-end' alignItems='center' sx={{
                                width: isMobile ? '100%' : undefined
                            }}>
                                {titleActions}
                            </Stack>
                        </Stack>

                        {
                            breadCrumbs && breadCrumbs.length > 0 && (
                                <Breadcrumbs>
                                    {
                                        breadCrumbs.map((x, index) => x.active || !x.routerLink ? (
                                            <Typography
                                                color={x.active ? 'primary' : 'text.disabled'}
                                                key={index}
                                            >
                                                {x.text}
                                            </Typography>
                                        ) : (
                                            <RouterLink to={x.routerLink!} key={index}>
                                                {x.text}
                                            </RouterLink>
                                        ))
                                    }
                                </Breadcrumbs>
                            )
                        }
                    </Stack>

                    {
                        isLoading && (
                            <PageLoader />
                        )
                    }

                    {
                        isPageLoadError ? (
                            <Alert severity='error'>
                                {DEFAULT_ERROR_MESSAGE}
                            </Alert>
                        ) : (
                            <Stack rowGap={rowGap ?? 5}>
                                {children}
                            </Stack>
                        )
                    }
                </Stack>
            </Container>
        </>
    )
};