import { convertToObject, stringifyError } from '@cw/utils';

type TLogMethod = (message: string, data?: any) => void;
interface ILogger {
  error: TLogMethod;
  warn: TLogMethod;
  info: TLogMethod;
  debug: TLogMethod;
}

const stringifyLogData = (data?: any): string => {
  if (!data || typeof data === 'string')  {
    return (data ?? '') as string;
  }
  return stringifyError(data);
}

export const logger: ILogger = ({
  error: (message: string, data?: any) => {
    const processedLogData = convertToObject(data)
    const logData = stringifyLogData(processedLogData);
    console.error(message, data);
    if (window.newrelic) {
      window.newrelic.noticeError(data && data instanceof Error ? data : message, {
        errorDataString: logData,
        errorDataProcessed: processedLogData,
        errorDescription: message
      });
    }
  },
  warn: (message: string, data?: any) => console.warn(message, data),
  info: (message: string, data?: any) => console.info(message, data),
  debug: (message: string, data?: any) => console.debug(message, data),
})