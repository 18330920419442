import React from 'react';

import { FormControlLabel, Switch, SwitchProps, Typography } from '@mui/material';

import { camelCaseToUserText } from '@cw/utils';

import { useFormContext } from './Form.Context';

interface IFormSwitchInputProps<T> extends Omit<SwitchProps, 'name' | 'disabled' | 'checked' | 'onChange'> {
    label?: string;
    name: keyof T;
    disabled?: boolean;
}
export function FormSwitchInput<T>(props: IFormSwitchInputProps<T>) {
    const {
        label,
        name,
        disabled,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        submitAttempted,
        customFormErrors
    } = useFormContext<T>();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        handleFormChange(name, newValue);
    };

    return (
      <FormControlLabel
        label={<Typography variant='body2'>{label ?? camelCaseToUserText(String(name))}</Typography>}
        sx={{
          marginRight: '0px',
          color: (submitAttempted && (formErrors[name] && !!customFormErrors[name])) ? 'var(--color-error)' : disabled || formDisabled ? 'var(--color-text-disabled)' : ''
        }}
        disabled={disabled || formDisabled}
        control={
          <Switch
            {...baseInputProps}
            onChange={handleInputChange}
            checked={(formData[name] as boolean) ?? false}
            disabled={disabled || formDisabled}
            color={(submitAttempted && (formErrors[name] && !!customFormErrors[name])) ? 'error' : baseInputProps.color}
            name={String(name)} />
        }
      />
    )
};