import { FunctionComponent, cloneElement } from 'react'

import { Stack, Typography } from '@mui/material'

import { Button } from '.';
import { useScreenSize } from '@cw/hooks';

interface IEmptyListCTAProps {
  icon: JSX.Element;
  body: string;
  ctaText?: string;
  ctaHelperText?: string;
  ctaClicked?: () => void;
  ctaLoading?: boolean;
}
export const EmptyListCTA: FunctionComponent<IEmptyListCTAProps> = (props: IEmptyListCTAProps) => {

  const {
    icon,
    body,
    ctaText,
    ctaHelperText,
    ctaClicked,
    ctaLoading
  } = props;

  const { isMobile } = useScreenSize();

  const getIcon = () => {
    return cloneElement(icon, {
      color: 'dark',
      sx: {
        fontSize: 80
      }
    });
  }

  return (
    <Stack justifyContent='center' alignItems='center' flexDirection='column' rowGap={3} padding={isMobile ? 1 : 15}>
      {getIcon()}
      <Typography variant='h5' textAlign='center'>
        {body}
      </Typography>
      {
        ctaText && (
          <Button onClick={ctaClicked} loading={ctaLoading}>
            {ctaText}
          </Button>
        )
      }
      {
        ctaHelperText && (
          <Typography variant='caption' textAlign='center' sx={{
            whiteSpace: 'break-spaces'
          }}>{ctaHelperText}</Typography>
        )
      }
    </Stack>
  )
}