import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

import { useFormContext } from './Form.Context';

import { Dayjs } from 'dayjs';
import { camelCaseToUserText } from '@cw/utils';
import { Typography } from '@mui/material';

type TDatePickerValue = Dayjs | null | undefined;

interface IFormDateInputProps<T> extends Omit<DatePickerProps<Dayjs>, 'name' | 'label' | 'disabled'> {
    name: keyof T;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    modifier?: 'startOfDay' | 'endOfDay';
    helperText?: string;
}
export function FormDateInput<T>(props: IFormDateInputProps<T>) {

    const {
        name,
        label,
        disabled,
        required,
        modifier,
        helperText,
        ...datePickerProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        customFormErrors,
        submitAttempted
    } = useFormContext<T>();

    const handleInputChange = (value: TDatePickerValue) => {
        let newValue = value;
        if (newValue && modifier === 'startOfDay') {
            newValue = newValue.startOf('day');
        } else if (newValue && modifier === 'endOfDay') {
            newValue = newValue.endOf('day');
        }

        handleFormChange(name, newValue);
    };

    return (
        <DatePicker
            {...datePickerProps}
            label={label ?? camelCaseToUserText(String(name))}
            format={props.format ?? 'DD MMM YYYY'}
            value={formData[name] as TDatePickerValue}
            onChange={handleInputChange}
            slotProps={{
                textField: {
                    error: submitAttempted && (formErrors[name] || !!customFormErrors[name]),
                    required: required,
                    helperText: submitAttempted && !!customFormErrors[name] ? (
                        <Typography variant='caption' color='error'>{customFormErrors[name]}</Typography>
                    ) : helperText ? (
                        <Typography variant='caption'>{helperText}</Typography>
                    ) : null
                },
                popper: {
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    altBoundary: false,
                                    fallbackPlacements: ['right', 'left'], // This is to prevent popper from being cut off at bottom or top, and just giving it the option to try to the left and right of the input as well
                                }
                            }
                        ]
                    }
                }
            }}
            disabled={disabled || formDisabled}
        />
    );
};