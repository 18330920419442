import { getSafeFileName } from "@cw/utils";
import { logger } from "./logger";
import { ALLOWED_AUDIO_MIME_TYPES } from "@cw/constants";
import { getAudioFileMimeType } from "@cw/utils/fileUtils";

export interface IDeviceAudio {
  dataUrl: string;
  file: File;
  durationSeconds: number;
}

const float32ToInt16 = (float32Array: Float32Array): Int16Array => {
  const int16Array = new Int16Array(float32Array.length);
  for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]));
      int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
  }
  return int16Array;
}

export const convertWebmToMp3 = async (webmBlob: Blob): Promise<Blob> => {
  // Convert to MP3 format
  const arrayBuffer = await webmBlob.arrayBuffer();
  const audioContext = new AudioContext();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const sampleRate = audioBuffer.sampleRate;
  const { Mp3Encoder } = await import('@breezystack/lamejs');
  const mp3Encoder = new Mp3Encoder(1, sampleRate, 128);

  const samples = audioBuffer.getChannelData(0);
  const mp3Data: Int8Array[] = [];
  const sampleBlockSize = 1152; // Number of samples per MP3 frame

  // Encode in blocks
  for (let i = 0; i < samples.length; i += sampleBlockSize) {
      const sampleChunk = samples.subarray(i, i + sampleBlockSize);
      const convertedChunk = float32ToInt16(sampleChunk);
      const mp3buf = mp3Encoder.encodeBuffer(convertedChunk);
      if (mp3buf.length > 0) {
          mp3Data.push(new Int8Array(mp3buf));
      }
  }

  // Finish the MP3 encoding
  const mp3buf = mp3Encoder.flush();
  if (mp3buf.length > 0) {
      mp3Data.push(new Int8Array(mp3buf));
  }

  // Create a Blob from the MP3 data
  return new Blob(mp3Data, { type: "audio/mpeg" });
}

export const getDeviceAudio = (overrideOutputFileName?: string): Promise<IDeviceAudio | null> => {
  return new Promise<IDeviceAudio | null>((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = ALLOWED_AUDIO_MIME_TYPES.join(',');

    let didRespond = false;

    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      const file: File | null = target.files ? target.files[0] : null;
      
      if (file) {
        const originalFileNameParts = file.name.split('.');
        const originalFileNameExtension = originalFileNameParts[originalFileNameParts.length - 1].toLowerCase();
        let fileNameToUse = overrideOutputFileName ?? file.name;
        if (overrideOutputFileName && !overrideOutputFileName.toLowerCase().endsWith(originalFileNameExtension)) {
          const overrideFileNameParts = overrideOutputFileName.split('.');
          const overrideFileNameExtension = overrideFileNameParts[overrideFileNameParts.length - 1].toLowerCase();
          fileNameToUse = fileNameToUse.replace(overrideFileNameExtension, originalFileNameExtension);
        }

        const dataUrl = URL.createObjectURL(file);
        const cleanedFileName = getSafeFileName(fileNameToUse);
        const pickedFileMimeType = getAudioFileMimeType(cleanedFileName, file.type);
        const webFile = new File([file], cleanedFileName, { type: pickedFileMimeType });
        const durationSeconds = await getAudioFileDuration(file);
        didRespond = true;
        resolve({
          dataUrl,
          file: webFile,
          durationSeconds
        });
      } else {
        if (!didRespond) {
          didRespond = true;
          resolve(null);
        }
      }
    };

    input.onabort = () => {
      if (!didRespond) {
        didRespond = true;
        resolve(null);
      }
    }
    input.onerror = () => {
      if (!didRespond) {
        didRespond = true;
        resolve(null);
      }
    }
    input.oncancel = () => {
      if (!didRespond) {
        didRespond = true;
        resolve(null);
      }
    }
    input.onclose = () => {
      if (!didRespond) {
        didRespond = true;
        resolve(null);
      }
    }

    input.click();
  });
}

export const getAudioFileDuration = async (file: Blob): Promise<number> => {
  try {

    const audioContext = new window.AudioContext();
    const arrayBuffer = await file.arrayBuffer();

    const decodedData = await audioContext.decodeAudioData(arrayBuffer);
    return isNaN(decodedData.duration) ? 0 : Math.round(decodedData.duration); 
  } catch (err) {
    logger.error("Error during getAudioFileDuration: ", err);
    return 0;
  }
}