import { FunctionComponent } from 'react';

import { TColor } from '@cw/models/shared';
import { Stack, StackProps } from '@mui/material';

import { InfoTooltip } from './InfoTooltip';

interface IInformationalWrapperProps extends StackProps {
  tooltip?: string;
  iconColor?: TColor;
  children: any;
}
export const InformationalWrapper: FunctionComponent<IInformationalWrapperProps> = (props: IInformationalWrapperProps) => {

  const {
    tooltip,
    iconColor,
    children,
    ...stackProps
  } = props;

  return (
    <Stack direction='row' columnGap={1} alignItems='center' {...stackProps}>
      {children}

      {tooltip && (
        <InfoTooltip
          tooltip={tooltip}
          color={iconColor}
        />
      )}
    </Stack>
  )
}