import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  IReducerAction,
  IAppSettings,
  QueryKeys,
  FeatureFlag,
} from '@cw/models/shared';

type TAppSettingsContextActions = 'SET_APP_SETTINGS';

interface IAppSettingsContext {
  initialized: boolean;
  appSettings: IAppSettings;
  featureEnabled: (featureFlag: FeatureFlag) => boolean;
}

const initialAppSettingsState: IAppSettings = {
  apiBaseUrl: '',
  blobBaseUrl: '',
  websocketApiBaseDomain: '',
  mobileAppWebsiteBaseDomain: '',
  internalAdminWebsiteBaseDomain: '',
  isInternalAdminMode: false,
  googleApiKey: '',
  stripe: {
    publishKey: '',
    serviceFeeFixed: 0,
    serviceFeePercentage: 0,
  },
  featureFlags: {},
  firebase: {
    apiKey: '',
    authDomain: '',
    measurementId: '',
    projectId: '',
    storageBucket: '',
  },
  firebaseInternal: {
    apiKey: '',
    authDomain: '',
    measurementId: '',
    projectId: '',
    storageBucket: '',
  },
  mobileAppSchemes: {
    android: '',
    ios: ''
  }
};
const initialState: IAppSettingsContext = {
  initialized: false,
  featureEnabled: () => false,
  appSettings: { ...initialAppSettingsState },
};
export const AppSettingsContext =
  React.createContext<IAppSettingsContext>(initialState);

const AppSettingsContextReducer = (
  state: IAppSettingsContext,
  action: IReducerAction<TAppSettingsContextActions>
): IAppSettingsContext => {
  switch (action.type) {
    case 'SET_APP_SETTINGS':
      return {
        ...state,
        initialized: !!action.payload,
        appSettings: action.payload,
      };
    default:
      return state;
  }
};

interface IAppSettingsContextProviderProps {
  children: any;
}
export const AppSettingsContextProvider: FunctionComponent<
  IAppSettingsContextProviderProps
> = (props: IAppSettingsContextProviderProps) => {
  const [state, dispatch] = useReducer(AppSettingsContextReducer, {
    ...initialState,
  });

  const { data: appSettings = null } = useQuery<IAppSettings>(
    [QueryKeys.AppSettings],
    async () => {
      const res = await fetch('/appsettings.json', {
        cache: 'no-store',
        method: 'GET',
      });
      return await res.json();
    },
    {
      refetchInterval: 60000,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: 0
    }
  );

  const featureEnabled = (featureFlag: FeatureFlag): boolean => {
    return (
      state.appSettings &&
      (state.appSettings.featureFlags[featureFlag] ?? false)
    );
  };

  useEffect(() => {
    dispatch({
      type: 'SET_APP_SETTINGS',
      payload: !appSettings ? appSettings : {
        ...appSettings,
        isInternalAdminMode: window.location.host === appSettings.internalAdminWebsiteBaseDomain
      } as IAppSettings
    });

    if (appSettings) {
      console.debug(
        `Environment: ${appSettings.environment}, Version: ${appSettings.buildVersion}`
      );
      if (window.newrelic && appSettings.buildVersion) {
        window.newrelic.setApplicationVersion(appSettings.buildVersion);
      }
    }
  }, [appSettings]);

  if (!state.initialized) {
    return null;
  }

  return (
    <AppSettingsContext.Provider
      value={{
        ...state,
        featureEnabled,
      }}
    >
      {props.children}
    </AppSettingsContext.Provider>
  );
};
