import React, { FunctionComponent, Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { AuthGuard, PageLoader } from "@cw/components";
import { ROUTES, getRouteName } from "@cw/constants";

import { AuthContextProvider } from "./contexts";
import { OnTheDayAuthGuard } from "./components/OnTheDayAuthGuard";
import { ErrorBoundary } from "./ErrorBoundary";

const LazyLoader =
  (Component: React.LazyExoticComponent<FunctionComponent>) => (props: any) =>
    (
      <Suspense fallback={<PageLoader />}>
        <Component {...props} />
      </Suspense>
    );

const LoginPage = LazyLoader(lazy(() => import("./pages/LoginPage")));
const DashboardPage = LazyLoader(lazy(() => import("./pages/DashboardPage")));
const CompetitionsListPage = LazyLoader(lazy(() => import("./pages/CompetitionsListPage")));
const ManageCompetitionPage = LazyLoader(lazy(() => import("./pages/ManageCompetitionPage")));

const EditAccountPage = LazyLoader(lazy(() => import("./pages/EditAccountPage")));

const CompWorldGuidesPage = LazyLoader(lazy(() => import("./pages/CompWorldGuidesPage")));

const OnTheDayLoginPage = LazyLoader(lazy(() => import("./pages/OnTheDayLoginPage")));
const OnTheDayLiveMusicPlayerPage = LazyLoader(lazy(() => import("./pages/OnTheDayLiveMusicPlayerPage")));
const OnTheDayStageLineupMonitorPage = LazyLoader(lazy(() => import("./pages/OnTheDayStageLineupMonitorPage")));
const OnTheDayAdjudicatorPage = LazyLoader(lazy(() => import("./pages/OnTheDayAdjudicatorPage")));

const WaitlistInvitePage = LazyLoader(lazy(() => import("./pages/WaitlistInvitePage")));

const AppRouter: FunctionComponent = () => {
  const routes = createBrowserRouter([
    {
      element: (
        <AuthContextProvider>
          <ErrorBoundary>
            <LoginPage />
          </ErrorBoundary>
        </AuthContextProvider>
      ),
      index: true,
    },
    {
      path: getRouteName(ROUTES.Dashboard),
      element: (
        <AuthContextProvider>
          <ErrorBoundary>
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          </ErrorBoundary>
        </AuthContextProvider>
      ),
      children: [
        {
          path: getRouteName(ROUTES.Competitions, ROUTES.Dashboard),
          element: <CompetitionsListPage />,
        },
        {
          path: getRouteName(ROUTES.ManageCompetition, ROUTES.Dashboard),
          element: <ManageCompetitionPage />,
        },
        {
          path: getRouteName(ROUTES.EditAccount, ROUTES.Dashboard),
          element: <EditAccountPage />,
        },
        {
          path: getRouteName(ROUTES.CompWorldGuidesPage, ROUTES.Dashboard),
          element: <CompWorldGuidesPage />,
        },
      ],
    },
    {
      path: getRouteName(ROUTES.OnTheDayLogin),
      element: (
        <ErrorBoundary>
          <OnTheDayLoginPage />
        </ErrorBoundary>
      )
    },
    {
      path: getRouteName(ROUTES.OnTheDayLiveMusicPlayer),
      element: (
        <ErrorBoundary>
          <OnTheDayAuthGuard linkType="MusicPlayer">
            <OnTheDayLiveMusicPlayerPage />
          </OnTheDayAuthGuard>
        </ErrorBoundary>
      )
    },
    {
      path: getRouteName(ROUTES.OnTheDayStageLineupMonitor),
      element: (
        <ErrorBoundary>
          <OnTheDayAuthGuard linkType="StageMonitor">
            <OnTheDayStageLineupMonitorPage />
          </OnTheDayAuthGuard>
        </ErrorBoundary>
      )
    },
    {
      path: getRouteName(ROUTES.OnTheDayAdjudicator),
      element: (
        <ErrorBoundary>
          <OnTheDayAuthGuard linkType="Adjudicator">
            <OnTheDayAdjudicatorPage />
          </OnTheDayAuthGuard>
        </ErrorBoundary>
      )
    },
    {
      path: getRouteName(ROUTES.WaitlistInvite),
      element: (
        <ErrorBoundary>
          <WaitlistInvitePage />
        </ErrorBoundary>
      )
    },
    {
      path: "*",
      element: <Navigate to={ROUTES.Root} replace />,
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default AppRouter;
