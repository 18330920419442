import React, { FunctionComponent } from 'react';

import { CircularProgress, Box, Typography } from '@mui/material';
import { TColor } from '@cw/models/shared';

interface IProgressSpinnerValue {
    value: number;
    color: TColor;
}
interface IProgressSpinnerProps {
    variant?: 'determinate' | 'indeterminate';
    value?: number | IProgressSpinnerValue[];
    progressLabelOverride?: React.ReactNode;
    showProgressLabel?: boolean;
    progressLabelSize?: 'small' | 'normal';
    size?: number;
    color?: TColor;
}
export const ProgressSpinner: FunctionComponent<IProgressSpinnerProps> = (props: IProgressSpinnerProps) => {

    const spinners = () => {
        if (Array.isArray(props.value)) {
            const orderedItems = props.value.slice().sort((a, b) => a < b ? -1 : b < a ? 1 : 0);
            return orderedItems.map((item, index) => (
                <CircularProgress
                    key={index}
                    size={props.showProgressLabel && !props.size ? 60 : props.size}
                    variant={props.variant}
                    value={Math.min(100, item.value)}
                    color={item.color}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        zIndex: index + 2
                    }} />
            ));
        } else {
            return (
                <CircularProgress
                    size={props.showProgressLabel && !props.size ? 60 : props.size}
                    variant={props.variant}
                    value={Math.min(100, props.value!)}
                    color={props.color ?? 'primary'}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        zIndex: 2
                    }} />
            )
        }
    };

    const spinnerWrapper = (
        <Box sx={{
            position: 'relative',
            height: !props.size ? 60 : props.size,
            width: !props.size ? 60 : props.size
        }}>
            <CircularProgress
                size={props.showProgressLabel && !props.size ? 60 : props.size}
                variant='determinate'
                value={100}
                sx={{
                    color: (theme) =>
                        theme.palette.grey[300],
                    zIndex: 1
                }} />

            {spinners()}
        </Box>
    );

    const getValueLabel = () => {
        if (props.progressLabelOverride) {
            return props.progressLabelOverride;
        } else if (!Array.isArray(props.value)) {
            return `${Math.round(props.value!)}%`;
        }

        return null;
    }

    if (props.variant === 'determinate' && props.showProgressLabel) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                {spinnerWrapper}

                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant='caption'
                        component='div'
                        sx={{
                            color: `var(--color-${props.color ?? 'primary'})`,
                            fontSize: props.progressLabelSize === 'small' ? '0.65rem' : null
                        }}
                    >
                        {getValueLabel()}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return spinnerWrapper;
};