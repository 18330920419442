import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './styles/AudioPlayer.scss';

import { Stack, Typography, Slider } from '@mui/material';
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';

import { IconButton } from '@cw/components/core';

interface IAudioPlayerProps {
  audioSourceLink: string;
  audioFileName: string;
  audioDurationSeconds: number;
  onLazyLoadAudioSourceLink?: () => void;
}
export const AudioPlayer: FunctionComponent<IAudioPlayerProps> = (props: IAudioPlayerProps) => {

  const {
    audioSourceLink,
    audioDurationSeconds,
    audioFileName,
    onLazyLoadAudioSourceLink
  } = props;

  const [playerState, setPlayerState] = useState<'init' | 'loading' | 'playing' | 'paused' | 'finished'>('init');
  const [playedDurationSeconds, setPlayedDurationSeconds] = useState(0);
  const [playerProgress, setPlayerProgress] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);

  const audioPlayer = useRef<HTMLAudioElement>(new Audio());

  const songDurationFormatted = useMemo(() => {
    const minutes = Math.floor(audioDurationSeconds / 60);
    const seconds = audioDurationSeconds - (minutes * 60);

    const playedMinutes = Math.floor(playedDurationSeconds / 60);
    const playedSeconds = playedDurationSeconds - (playedMinutes * 60);

    return `${playedMinutes}:${playedSeconds.toString().padStart(2, '0')} / ${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, [audioDurationSeconds, playedDurationSeconds]);

  const handlePlayerClicked = () => {
    if (!audioPlayer.current) return;

    if (playerState === 'init') {
      setPlayerState('loading');
      if (onLazyLoadAudioSourceLink && !audioSourceLink) {
        onLazyLoadAudioSourceLink();
      }
    } else if (playerState === 'loading' && audioSourceLink) {
      audioPlayer.current.src = audioSourceLink;
      audioPlayer.current.load();
      audioPlayer.current.play().then(() => {
        setPlayerState('playing');
      });
    } else if (playerState === 'playing') {
      audioPlayer.current.pause();
      setPlayerState('paused');
    } else if (playerState === 'paused') {
      audioPlayer.current.play();
      setPlayerState('playing');
    } else if (playerState === 'finished') {
      audioPlayer.current.currentTime = 0;
      audioPlayer.current.play();
      setPlayerState('playing');
    }
  }

  const handlePlayerCurrentTimeChanged = useCallback(() => {
    const duration = Math.round(audioPlayer.current.currentTime);
    setPlayedDurationSeconds(duration);
    setPlayerProgress((duration / audioDurationSeconds) * 100);
  }, [audioDurationSeconds]);
  const handlePlayerEnded = () => {
    setPlayerState('finished');
  }
  const handlePlayerCanPlay = () => {
    setIsBuffering(false);
  };
  const handlePlayerWaiting = () => {
    setIsBuffering(true);
  }

  const handleProgressSeek = (percentage: number) => {
    const secondsToSeekTo = Math.round(audioDurationSeconds * (percentage / 100));
    setPlayedDurationSeconds(secondsToSeekTo);
    setPlayerProgress((secondsToSeekTo / audioDurationSeconds) * 100);

    if (audioPlayer.current) {
      audioPlayer.current.currentTime = secondsToSeekTo;
      if (playerState === 'finished') {
        audioPlayer.current.play();
        setPlayerState('playing');
      }
    }
  }

  const stopAudioPlayer = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;
      audioPlayer.current.src = '';
      audioPlayer.current.load();

      audioPlayer.current.removeEventListener('timeupdate', handlePlayerCurrentTimeChanged);
      audioPlayer.current.removeEventListener('ended', handlePlayerEnded);
      audioPlayer.current.removeEventListener('canplay', handlePlayerCanPlay);
      audioPlayer.current.removeEventListener('waiting', handlePlayerWaiting);
    }
  };

  useEffect(() => {
    if (audioSourceLink && playerState === 'loading') {
      handlePlayerClicked();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSourceLink, playerState]);

  useEffect(() => {
    setPlayerState('init');
    setPlayedDurationSeconds(0);
    setPlayerProgress(0);

    audioPlayer.current.addEventListener('timeupdate', handlePlayerCurrentTimeChanged);
    audioPlayer.current.addEventListener('ended', handlePlayerEnded);
    audioPlayer.current.addEventListener('canplay', handlePlayerCanPlay);
    audioPlayer.current.addEventListener('waiting', handlePlayerWaiting);

    return () => {
      stopAudioPlayer();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSourceLink, audioDurationSeconds, audioFileName]);

  return (
    <Stack
      className='cw-audio-player'
      direction='column'
      rowGap={1}
    >
      <Stack direction='row' columnGap={3} alignItems='center'>
        <IconButton
          size='small'
          icon={playerState === 'init' || playerState === 'paused' || playerState === 'finished' ? <PlayCircleOutline /> : <PauseCircleOutline />}
          loading={playerState === 'loading' || isBuffering}
          loadingSpinnerSize={24}
          onClick={handlePlayerClicked}
        />

        <Slider
          disabled={playerState !== 'playing' && playerState !== 'paused' && playerState !== 'finished'}
          value={playerProgress}
          min={0}
          max={100}
          onChange={(_, newValue) => handleProgressSeek(newValue as number)}
        />

        <Typography variant='body3' flexShrink={0}>
          {songDurationFormatted}
        </Typography>
      </Stack>
      <Typography variant='body3'>{audioFileName}</Typography>
    </Stack>
  )
}