import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { IReducerAction } from '@cw/models/shared';
import { IUser } from '@cw/models/self';
import { useInitializeAuthMutation } from '@cw/hooks/api/AuthApi';
import { useGetSelf } from '@cw/hooks/api/SelfApi';
import { useFirebase } from '@cw/hooks';

type TAuthContextActions = 'SET_USER' | 'SET_INITIALIZED' | 'SET_IS_AUTHENTICATING';

interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isAuthenticating: boolean;
  user?: IUser;
}
const initialAuthState: IAuthContext = {
  isAuthenticated: false,
  isInitialized: false,
  isAuthenticating: false
};
export const AuthContext = React.createContext(initialAuthState);

const AuthContextReducer = (
  state: IAuthContext,
  action: IReducerAction<TAuthContextActions>
): IAuthContext => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload, isAuthenticated: !!action.payload };
    case 'SET_INITIALIZED':
      return { ...state, isInitialized: action.payload };
    case 'SET_IS_AUTHENTICATING':
      return { ...state, isAuthenticating: action.payload };
    default:
      return state;
  }
};

interface IAuthContextProviderProps {
  children: any;
}
export const AuthContextProvider: FunctionComponent<
  IAuthContextProviderProps
> = (props: IAuthContextProviderProps) => {
  
  const {
    isAuthorized: isFirebaseAuthorized,
    authorizedUser,
    isInitialized: isFirebaseInitialized
  } = useFirebase();

  const [state, dispatch] = useReducer(AuthContextReducer, {
    ...initialAuthState,
  });

  const { mutate: initializeAuth, isLoading: initializeAuthLoading } = useInitializeAuthMutation({
    onSuccess: (user) => {
      dispatch({ type: 'SET_USER', payload: user });
      dispatch({ type: 'SET_INITIALIZED', payload: true });
    },
    onError: () => {
      dispatch({ type: 'SET_USER', payload: null });
      dispatch({ type: 'SET_INITIALIZED', payload: true });
    }
  });
  const { data: getSelfResult } = useGetSelf({
    enabled: isFirebaseAuthorized,
    autoShowErrorMessages: false
  });

  useEffect(() => {
    if (isFirebaseAuthorized) {
      initializeAuth({
        name: authorizedUser?.name ?? '',
        surname: authorizedUser?.surname ?? ''
      });
    } else {
      dispatch({
        type: 'SET_USER',
        payload: null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirebaseAuthorized]);

  useEffect(() => {
    if (isFirebaseInitialized && !isFirebaseAuthorized) {
      dispatch({
        type: 'SET_INITIALIZED',
        payload: true
      });
    }
  }, [isFirebaseInitialized, isFirebaseAuthorized]);

  useEffect(() => {
    dispatch({
      type: 'SET_IS_AUTHENTICATING',
      payload: initializeAuthLoading
    });
  }, [initializeAuthLoading]);

  useEffect(() => {
    if (getSelfResult) {
      dispatch({
        type: 'SET_USER',
        payload: getSelfResult
      });

      if (window.newrelic) {
        window.newrelic?.setUserId(getSelfResult.id);
      }
    }
  }, [getSelfResult]);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
};
