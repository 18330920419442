import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { logger } from '@cw/services/logger';
import { THttpMethod, HttpClientError } from '@cw/models/shared';
import { useAppSettings, useSnackbar, useFirebase, useLocalDb } from '@cw/hooks';
import { DEFAULT_ERROR_MESSAGE, ROUTES } from '@cw/constants';
import dayjs from 'dayjs';
import { IOnTheDayUserAuthDetails, LocalDbNames, LocalDbStores } from '@cw/models/localDb';
import { cleanApiResponse, formatRoute } from '@cw/utils';

type TQueryParams = {[key: string]: any};


interface IHttpClientRequestOptions {
    /**
     * Should the user be logged out if API returns 401 or 403 errors. Defaults to `true`
     */
    autoLogOutOnAuthError?: boolean;

    /**
     * Should the error message be shown by default. Defaults to `true`
     */
    autoShowErrorMessages?: boolean;
}

interface IHttpClientContext {
    POST: <T>(url: string, body?: any, file?: File, options?: IHttpClientRequestOptions) => Promise<T>;
    PUT: <T>(url: string, body?: any, options?: IHttpClientRequestOptions) => Promise<T>;
    DELETE: <T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions) => Promise<T>;
    GET: <T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions) => Promise<T>;
    buildUrl: (url: string, queryParams?: TQueryParams) => string;
}
const initialState: IHttpClientContext = {
    POST: () => Promise.resolve<any>({}),
    PUT: () => Promise.resolve<any>({}),
    DELETE: () => Promise.resolve<any>({}),
    GET: () => Promise.resolve<any>({}),
    buildUrl: () => ''
}
export const HttpClientContext = React.createContext<IHttpClientContext>(initialState);

const extractErrorMessages = (responseBody: any): string[] => {
    if (responseBody.errors && typeof responseBody.errors === 'object' && !Array.isArray(responseBody.errors) && Object.keys(responseBody.errors).length > 0) {
        const validErrors = Object.keys(responseBody.errors).filter(x => !x.startsWith('$'));
        if (validErrors.length > 0) {
            return validErrors.flatMap(key => responseBody.errors[key]);
        }
    } else if (responseBody.detail) {
        return [responseBody.detail];
    } else if (responseBody.message) {
        return [responseBody.message];
    }

    return [DEFAULT_ERROR_MESSAGE];
};

const getHttpClientRequestOptions = (options?: IHttpClientRequestOptions): IHttpClientRequestOptions => {
    const defaultOptions: IHttpClientRequestOptions = {
        autoLogOutOnAuthError: true,
        autoShowErrorMessages: true
    };

    return {
        ...defaultOptions,
        ...(options ?? {})
    }
}

const buildFetchOptions = (method: THttpMethod, accessToken: string | null, body?: any, file?: File): RequestInit => {
    const options: RequestInit = {
        method,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-Timezone-Offset': `${new Date().getTimezoneOffset() * -1}`,
            'X-Timezone-Name': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    };

    if (file) {
        options.headers = {};
    }

    if (accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }

    if (!file) {
        options.body = JSON.stringify(body);
    } else if (file) {
        const formData = new FormData();
        formData.append('file', file);
        if (body) {
            formData.append('request', JSON.stringify(body));
        }
        options.body = formData;
    }
    return options;
};

export const buildQueryParamString = (params?: {[key: string]: any}): string => {
    if (!params) {
        return '';
    }

    const queryString = Object.keys(params)
        .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
        .map(key => {
            let paramString = params[key];
            if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
                paramString = JSON.stringify(params[key]); // This will generally be Date objects, so stringify will convert to ISO date

                if (dayjs.isDayjs(params[key])) { // Check if this is a dayjs object
                    paramString = params[key].toISOString();
                } else if (dayjs(params[key]).isValid()) { // Check if this is a Date objet
                    paramString = dayjs(params[key]).toISOString();
                }
            } else if (Array.isArray(params[key])) {
                const paramStringParts: string[] = [];
                for (const item of params[key]) {
                    paramStringParts.push(`${key}=${encodeURIComponent(item)}`);
                }
                return paramStringParts.join('&');
            }
            return `${key}=${encodeURIComponent(paramString)}`;
        })
        .join('&');

    return `?${queryString}`;
};

async function downloadResponseAsFile<T>(response: Response): Promise<T> {
    const responseHeaders = Object.fromEntries(response.headers.entries());
    const contentDispositionParts = responseHeaders['content-disposition'].split(';').map(x => x.trim());
    const fileNameParts = contentDispositionParts.find(x => x.toLowerCase().startsWith('filename='))?.split('=') ?? []; 

    const blobResponse = await response.blob();
    const url = window.URL.createObjectURL(blobResponse);
    const a = document.createElement('a');
    a.href = url;
    if (fileNameParts.length > 0) {
        a.download = fileNameParts[fileNameParts.length - 1].replaceAll('"', '');
    }
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();

    return { message: 'File download will begin shortly' } as T
}

interface IHttpClientContextProviderProps {
    children: any;
}
export const HttpClientContextProvider: FunctionComponent<IHttpClientContextProviderProps> = (props: IHttpClientContextProviderProps) => {

    const { appSettings } = useAppSettings();
    const { showError } = useSnackbar();
    const { children } = props;

    const { getAccessToken } = useFirebase();
    const { getItem: getOnTheDayAuthDetails, deleteItem: deleteOnTheDayAuthDetails } = useLocalDb(LocalDbNames.OnTheDay, [LocalDbStores.OnTheDayAuthDetails]);

    const buildUrl = useCallback((url: string, params?: TQueryParams): string => {
        const queryString = buildQueryParamString(params);
        return `${url.startsWith('http') ? '' : appSettings.apiBaseUrl}${url}${queryString}`;
    }, [appSettings]);

    const execute = useCallback(async function <T>(method: THttpMethod, url: string, queryParams?: TQueryParams, body?: any, file?: File, options?: IHttpClientRequestOptions): Promise<T> {
        const fetchUrl = buildUrl(url, queryParams);
        const httpOptions = getHttpClientRequestOptions(options);

        try {
            let accessToken: string | null = '';
            let otdId = '';
            if (window.location.pathname.startsWith('/otd')) {
                const pathNameParts = window.location.pathname.split('/');
                if (pathNameParts.length >= 3) {
                    otdId = pathNameParts[2];
                    const authDetails = await getOnTheDayAuthDetails<IOnTheDayUserAuthDetails>(LocalDbStores.OnTheDayAuthDetails, otdId);
                    if (authDetails) {
                        accessToken = authDetails.accessToken;
                    }
                }
            } else {
                accessToken = await getAccessToken();
            }
            const fetchOptions = buildFetchOptions(method, accessToken, body, file);

            const fetchResponse = await fetch(fetchUrl, fetchOptions);
            if (fetchResponse.status === 401 || fetchResponse.status === 403) {
                if (httpOptions.autoLogOutOnAuthError) {
                    if (otdId) {
                        await deleteOnTheDayAuthDetails(LocalDbStores.OnTheDayAuthDetails, otdId);
                    }
                    window.location.href = otdId ? formatRoute(ROUTES.OnTheDayLogin, { linkId: otdId }) : ROUTES.Login;
                }

                throw new HttpClientError({
                    statusCode: fetchResponse.status,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    errorMessages: ['Fetch returned authentication error']
                });
            }

            const responseHeaders = Object.fromEntries(fetchResponse.headers.entries());
            if (responseHeaders['content-disposition'] && responseHeaders['content-disposition'].indexOf('attachment') >= 0) {
                return await downloadResponseAsFile(fetchResponse);
            }

            const response = await fetchResponse.json();
            if (fetchResponse.ok) {
                cleanApiResponse(response);
            }

            if (!fetchResponse.ok) {
                throw new HttpClientError({
                    statusCode: fetchResponse.status,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    rawErrors: response.errors,
                    errorMessages: extractErrorMessages(response)
                });
            }

            return response as T;
        } catch (err: any) {

            let httpClientError: HttpClientError;

            if (err instanceof HttpClientError) {
                httpClientError = err;
            } else {
                httpClientError = new HttpClientError({
                    statusCode: 500,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    errorMessages: [DEFAULT_ERROR_MESSAGE],
                    rawErrors: err
                });

                const errorMessage = (err.message ?? '') as string;
                const errorStack = (err.stack?.toString() ?? '') as string;
                if (!errorMessage.toLowerCase().includes('load failed') && !errorStack.toLowerCase().includes('failed to fetch')) {
                    logger.error(`Error while performing ${method} to "${fetchUrl}"`, httpClientError.errorResponse);
                }
            }

            if (httpOptions.autoShowErrorMessages) {
                showError(httpClientError.firstErrorMessage);
            }

            throw httpClientError;
        }
    }, [buildUrl, getAccessToken, showError, deleteOnTheDayAuthDetails, getOnTheDayAuthDetails]); 

    const POST = useCallback(async function <T>(url: string, body?: any, file?: File, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('POST', url, undefined, body, file, options);
    }, [execute]);

    const PUT = useCallback(async function <T>(url: string, body?: any, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('PUT', url, undefined, body, undefined, options);
    }, [execute]);

    const DELETE = useCallback(async function <T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('DELETE', url, queryParams, undefined, undefined, options);
    }, [execute]);

    const GET = useCallback(async function GET<T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('GET', url, queryParams, undefined, undefined, options);
    }, [execute]);

    const client = useMemo(() => ({
        POST,
        GET,
        DELETE,
        PUT,
        buildUrl
    }), [POST, GET, DELETE, PUT, buildUrl]);

    return (
        <HttpClientContext.Provider value={client}>
            {children}
        </HttpClientContext.Provider>
    )
};

