import {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from "react";
import "./styles/GeoMap.scss";

import { Stack } from "@mui/material";

import { ProgressSpinner } from "../ProgressSpinner";
import { GoogleMapsLibraryLoader } from "../GoogleMapsLibraryLoader";
import { IGeoPoint } from "@cw/models/shared";
import { createResizeObserver } from "@cw/utils/resizeObserverUtils";
import { GeoMarker } from "./GeoMarker";

// Defaults to Spark Arena, Auckland, NZ
export const DEFAULT_MAP_CENTER: IGeoPoint = {
  latitude: -36.8477629,
  longitude: 174.7765824,
};

interface IGeoMapProps {
  heightRatio?: number;
  fullHeight?: boolean;
  zoom?: number;
  marker?: IGeoPoint;
  hideControls?: boolean;
  wrapperAutoHeight?: boolean;
}

const Map: FunctionComponent<IGeoMapProps> = (props: IGeoMapProps) => {
  const mapElRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (!mapElRef.current || props.fullHeight) {
      // Reset height incase it was previously set
      if (
        props.fullHeight &&
        mapElRef.current &&
        mapElRef.current.style.height !== ""
      ) {
        mapElRef.current.style.height = "";
      }
      return;
    }

    const setMapHeight = () => {
      if (!mapElRef.current) {
        return;
      }

      const newHeight = mapElRef.current.clientWidth * (props.heightRatio ?? 0.6);
      mapElRef.current.style.height = `${newHeight}px`;
    };
    const resizeObserver = createResizeObserver(() => {
      setMapHeight();
    });
    resizeObserver.observe(mapElRef.current);
    setMapHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [mapElRef, props.heightRatio, props.fullHeight]);

  useEffect(() => {
    if (!mapElRef.current || map) {
      return;
    }

    setMap(
      new google.maps.Map(mapElRef.current, {
        zoom: 8,
        center: {
          lat: DEFAULT_MAP_CENTER.latitude,
          lng: DEFAULT_MAP_CENTER.longitude,
        },
        clickableIcons: false,
        disableDefaultUI: props.hideControls
      })
    );
  }, [mapElRef, map, props.hideControls]);

  useEffect(() => {
    if (!map || !props.zoom) {
      return;
    }

    if (props.zoom !== map.getZoom()) {
      map.setZoom(props.zoom);
    }
  }, [map, props.zoom]);

  useEffect(() => {
    if (!map || !props.marker) {
      return;
    }

    const currentCenter = map.getCenter();
    if (
      currentCenter &&
      (props.marker.latitude !== currentCenter.lat() ||
      props.marker.longitude !== currentCenter.lng())
    ) {
      map.panTo({
        lat: props.marker.latitude,
        lng: props.marker.longitude,
      });
    }
  }, [map, props.marker]);

  return (
    <>
      <div ref={mapElRef} className="geo-map"></div>
      {
        props.marker && map && (
          <GeoMarker
            map={map}
            position={props.marker}
          />
        )
      }
    </>
  );
};

export const GeoMap: FunctionComponent<IGeoMapProps> = (props: IGeoMapProps) => {
  const render = (status: string) => {
    return (
      <Stack display="flex" alignItems="center" sx={{ margin: "30px 0" }} data-status={status}>
        <ProgressSpinner />
      </Stack>
    );
  };

  return (
    <div
      className={`geo-map-wrapper ${
        props.wrapperAutoHeight ? "auto-height" : "full-height"
      }`}
    >
      <GoogleMapsLibraryLoader
        loadingRender={render}
      >
        <Map {...props} />  
      </GoogleMapsLibraryLoader>
    </div>
  );
};
