export const isSafariBrowser = (): boolean => {
  // Get the user agent string
  const userAgent = navigator.userAgent;

  // Check for Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isSafari;
}

type OsType = "Windows" | "macOS" | "Android" | "iOS" | "Linux" | "Unknown";
type DeviceInfo = {
  browser: string;
  os: OsType;
  deviceType: string;
  userAgent: string;
};

export const getDeviceInfo = (): DeviceInfo => {
  const userAgent = navigator.userAgent;

  const browser: string = (() => {
    if (userAgent.includes("Edg")) return "Edge";
    if (userAgent.includes("Chrome")) return "Chrome";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
    return "Unknown";
  })();

  const os: OsType = (() => {
    if (userAgent.includes("Windows NT")) return "Windows";
    if (userAgent.includes("iPhone") || userAgent.includes("iPad")) return "iOS";
    if (userAgent.includes("Mac OS X")) return "macOS";
    if (userAgent.includes("Android")) return "Android";
    if (userAgent.includes("Linux")) return "Linux";
    return "Unknown";
  })();

  const isMobile: boolean = /Mobi|Android/i.test(userAgent);
  const deviceType: string = isMobile ? "Mobile" : "Desktop";

  return {
    browser,
    os,
    deviceType,
    userAgent
  };
};