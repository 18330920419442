import { IUser } from "@cw/models/self";
import { IAppSettings } from "@cw/models/shared";
import { getDeviceInfo } from "./userAgentUtils";

interface ISupportEmail {
  subject: string;
  body: string;
  toEmail: string;
  href: string;
}
export const buildSupportEmail = (appSettings: IAppSettings, user?: IUser): ISupportEmail => {
  const deviceInfo = getDeviceInfo();
  const emailBody = [
    'Please tell us what you would like help with below:',
    '',
    '',
    '',
    'Kind Regards',
    '[YOUR FULL NAME]',
    '',
    '__________________________________________________________',
    'App Info (For support purposes, please do not edit or remove):',
    `Version: ${appSettings.buildVersion}`,
    `Browser: ${deviceInfo.browser}`,
    `OS: ${deviceInfo.os}`,
    `Device Type: ${deviceInfo.deviceType}`,
    `UID: ${user?.id ?? 'N/A'}`,
    `Env: ${appSettings.environment}`,
    '__________________________________________________________',
  ];

  const subject = 'Comp World Host App Issues';
  return {
    body: emailBody.join('\n'),
    subject: subject,
    toEmail: 'support@compworld.co.nz',
    href: `mailto:support@compworld.co.nz?subject=Comp World Host App Issues&body=${emailBody.join('%0D%0A')}`
  };
}