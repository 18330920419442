import { useMutation, useQuery, useQueryClient } from "react-query";

import { HttpClientError, IQuerySettings, QueryKeys, getQuerySettings } from '@cw/models/shared';
import { IUpdateSelfRequest, IUser } from '@cw/models/self';
import { useHttpClient, useSnackbar } from '@cw/hooks';

const BASE_URL = '/v1/self';

export const useGetSelf = (settings?: IQuerySettings<IUser>) => {
    const { GET } = useHttpClient();

    const querySettings = getQuerySettings(settings);
    return useQuery<IUser, HttpClientError>([QueryKeys.Self], () => {
        return GET<IUser>(`${BASE_URL}`, undefined, {
          autoShowErrorMessages: querySettings.autoShowErrorMessages,
          autoLogOutOnAuthError: querySettings.autoLogOutOnAuthError
        });
    }, querySettings);
}

export const useUpdateSelfMutation = (settings?: IQuerySettings<IUser, IUpdateSelfRequest>) => {
    const { PUT } = useHttpClient();
    const queryClient = useQueryClient();
    const { showSnackbar } = useSnackbar();

    const updateSelf = async (request: IUpdateSelfRequest) => {
        return PUT<IUser>(`${BASE_URL}`, request);
    }

    const querySettings = getQuerySettings(settings)
    return useMutation<IUser, HttpClientError, IUpdateSelfRequest>(updateSelf, {
        onSuccess: (response, input) => {
          showSnackbar('Successfully updated account details.')
          queryClient.setQueryData([QueryKeys.Self], response);
          if (querySettings.onSuccess) {
            querySettings.onSuccess(response, input)
          }
        },
        onError: querySettings.onError
    })
}