import { FunctionComponent, HTMLAttributeAnchorTarget, useState } from 'react';

import {
    IconButton as MuiIconButton,
    IconButtonProps,
    Tooltip,
    Menu as MuiMenu,
    MenuItem as MuiMenuItem,
    Divider,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

import { ProgressSpinner } from './ProgressSpinner';
import { IMenuItem } from '@cw/models/shared';

interface IIconButtonProps extends IconButtonProps {
    loading?: boolean;
    loadingSpinnerSize?: number;

    icon: JSX.Element;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    tooltip?: string;
    menuOptions?: IMenuItem[];
    onMenuOptionSelected?: (option: IMenuItem) => void;
}
export const IconButton: FunctionComponent<IIconButtonProps> = (props: IIconButtonProps) => {
    const {
        loading,
        icon,
        tooltip,
        menuOptions,
        onMenuOptionSelected,
        onClick,
        loadingSpinnerSize,
        ...baseButtonProps
    } = props;

    const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

    const iconToRender = loading
        ? (<ProgressSpinner size={loadingSpinnerSize ?? 20} />)
        : icon;

    const button = (
        <MuiIconButton
            {...baseButtonProps}
            disabled={props.disabled || loading}
            aria-controls={!!menuAnchorElement ? 'basic-menu' : undefined}
            aria-haspopup={!!menuOptions && menuOptions.length > 0}
            aria-expanded={!!menuAnchorElement ? 'true' : undefined}
            onClick={(e) => {
                if (!!menuOptions && menuOptions?.length > 0) {
                    setMenuAnchorElement(e.currentTarget);
                }
                
                if (onClick) {
                    onClick(e);
                }
            }}
        >
            {iconToRender}
        </MuiIconButton>
    )

    const buttonMenu = !!menuOptions && menuOptions.length > 0 ? (
        <MuiMenu
            anchorEl={menuAnchorElement}
            open={!!menuAnchorElement}
            onClose={() => setMenuAnchorElement(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{maxHeight: '1000px'}}
        >
            {
                menuOptions.filter(x => !x.hidden).map((x, index) => x.isDivider ? (
                    <Divider key={index} />
                ) : (
                    <MuiMenuItem
                        key={index}
                        onClick={() => {
                            setMenuAnchorElement(null);
                            if (onMenuOptionSelected) {
                                onMenuOptionSelected(x)
                            }
                        }}
                        disabled={x.disabled}
                    >
                        {x.icon && (
                            <ListItemIcon>
                                {x.icon}
                            </ListItemIcon>
                        )}
                        {x.content ?? (
                            <ListItemText disableTypography>
                                <Typography variant='body2' maxWidth='200px' whiteSpace='normal' color={x.color}>
                                    {x.text}
                                </Typography>
                            </ListItemText>
                        )}
                    </MuiMenuItem>
                ))
            }
        </MuiMenu>
    ) : null;

    if (tooltip) {
        return (
            <>
                <Tooltip title={tooltip}>
                    {button}
                </Tooltip>
                {buttonMenu}
            </>
        )
    }

    return (
        <>
            {button}
            {buttonMenu}
        </>
    );
};