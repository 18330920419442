import { useEffect, useState, useCallback, useMemo } from "react";

import { logger } from "@cw/services/logger";
import { ILocalDbBase } from "@cw/models/localDb";

import pubSub, { PubSubEvent } from '@cw/services/pubSubService';

export interface IDbRowChangedEvent<T> {
  dbName: string;
  storeName: string;
  key: IDBValidKey;
  operation: 'UPDATE' | 'DELETE';
  row?: T;
}

interface ILocalDb {
  isLocalDbInitialized: boolean;
  setItem: <T extends ILocalDbBase>(storeName: string, item: T) => Promise<void>;
  getItem: <T extends ILocalDbBase>(storeName: string, key: IDBValidKey) => Promise<T | undefined>;
  deleteItem: (storeName: string, key: IDBValidKey) => Promise<void>;
  getAllKeys: (storeName: string, keyPredicate?: (key: IDBValidKey) => boolean) => Promise<IDBValidKey[]>;
  getAllItems: <T extends ILocalDbBase>(storeName: string, keyPredicate?: (key: IDBValidKey) => boolean) => Promise<T[]>;
  getRemainingStorageInBytes: () => Promise<number>;
  buildDbKey: (...params: string[]) => string;
}

export const useLocalDb = (dbName: string, storeNames: string[], version: number = 1): ILocalDb => {
  const [db, setDb] = useState<IDBDatabase | null>(null);
  const [isLocalDbInitialized, setIsLocalDbInitialized] = useState<boolean>(false);

  const config = useMemo(() => ({
    dbName,
    storeNames,
    version
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [dbName, JSON.stringify(storeNames), version]);

  useEffect(() => {
    const openRequest = indexedDB.open(config.dbName, config.version);

    openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      config.storeNames.forEach((storeName) => {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'id' });
        }
      });
    };

    openRequest.onsuccess = () => {
      setDb(openRequest.result);
      setIsLocalDbInitialized(true);
    };

    openRequest.onerror = () => {
      logger.error('IndexedDB error:', openRequest.error);
    };
  }, [config]);

  useEffect(() => {
    if (navigator.storage && !!navigator.storage.persist) {
      navigator.storage.persisted().then(isPersisted => {
        if (!isPersisted) {
          navigator.storage.persist().then((success) => {
            logger.debug(`Storage Persist Status: ${success}`);
          });
        }
      })
    }
  }, []);

  const performTransaction = useCallback(
    async <U,>(
      storeName: string,
      mode: IDBTransactionMode,
      action: (store: IDBObjectStore) => IDBRequest<U>
    ): Promise<U> => {
      return new Promise<U>((resolve, reject) => {
        if (!db) {
          return reject(new Error("Database is not initialized"));
        }
        const transaction = db.transaction(storeName, mode);
        const store = transaction.objectStore(storeName);
        const request = action(store);

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
    },
    [db]
  );

  const getItem = useCallback(
    async <T extends ILocalDbBase>(storeName: string, key: IDBValidKey): Promise<T | undefined> => {
      return await performTransaction(storeName, "readonly", (store) =>
        store.get(key)
      );
    },
    [performTransaction]
  );

  const setItem = useCallback(
    async <T extends ILocalDbBase>(storeName: string, item: T) => {
      await performTransaction(storeName, "readwrite", (store) =>
        store.put(item)
      );
      pubSub.publish<IDbRowChangedEvent<T>>(PubSubEvent.IndexDbRowChanged, {
        dbName,
        storeName,
        key: item.id,
        operation: 'UPDATE',
        row: item
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performTransaction]
  );

  const deleteItem = useCallback(
    async (storeName: string, key: IDBValidKey) => {
      await performTransaction(storeName, "readwrite", (store) =>
        store.delete(key)
      );
      pubSub.publish<IDbRowChangedEvent<ILocalDbBase>>(PubSubEvent.IndexDbRowChanged, {
        dbName,
        storeName,
        key: key,
        operation: 'DELETE'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performTransaction]
  );

  const getAllKeys = useCallback(async (storeName: string, keyPredicate?: (key: IDBValidKey) => boolean): Promise<IDBValidKey[]> => {
    return await performTransaction(storeName, "readonly", (store) =>
      store.getAllKeys()
    ).then(keys => {
      if (!keyPredicate) {
        return keys;
      }
      return keys.filter(x => keyPredicate(x));
    });
  }, [performTransaction]);

  const getAllItems = useCallback(async <T extends ILocalDbBase>(storeName: string, keyPredicate?: (key: IDBValidKey) => boolean): Promise<T[]> => {
    return await getAllKeys(storeName, keyPredicate)
      .then(async (keys) => {
        const items = [];
        for (const key of keys) {
          const value = await getItem<T>(storeName, key);
          if (value) {
            items.push(value);
          }
        }
        return items;
      });
  }, [getItem, getAllKeys]);

  const getRemainingStorageInBytes = useCallback(async (): Promise<number> => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const estimate = await navigator.storage.estimate();
      const used = estimate.usage ?? 0;
      const quota = estimate.quota ?? 0;
      return quota - used;
    }

    return 0;
  }, []);

  const buildDbKey = useCallback((...params: string[]): string => params.join('_'), []);

  return {
    isLocalDbInitialized,
    setItem,
    getItem,
    deleteItem,
    getAllKeys,
    getAllItems,
    getRemainingStorageInBytes,
    buildDbKey
  };
};
