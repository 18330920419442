import React, { FunctionComponent, useReducer } from 'react';

import { IReducerAction } from '@cw/models/shared';
import { Snackbar, TSnackbarSeverity } from '@cw/components/core';

type TSnackbarActions = 'SHOW_MESSAGE' | 'CLEAR_MESSAGE';

interface ISnackbarContext {
    /**
     * Shows a snackbar message
     * @param message The message to show
     * @param severity The severity of the message. Defaults to `success`
     */
    showSnackbar: (message: string, severity?: TSnackbarSeverity) => void;

    /**
     * Shows a snackbar error message
     * @param message The message to show
     */
    showError: (message: string) => void;

    message: string;
    severity: TSnackbarSeverity;
}

const initialState: ISnackbarContext = {
    message: '',
    severity: 'success',
    showSnackbar: () => null,
    showError: () => null
};
export const SnackbarContext = React.createContext<ISnackbarContext>(initialState);

const SnackbarContextReducer = (state: ISnackbarContext, action: IReducerAction<TSnackbarActions>): ISnackbarContext => {
    switch (action.type) {
        case 'SHOW_MESSAGE':
            return {...state, ...action.payload};
        case 'CLEAR_MESSAGE':
            return {...state, message: ''}
        default:
            return state;
    }
};

const providerActions = {
    showSnackbar: (dispatcher: React.Dispatch<IReducerAction<TSnackbarActions>>, message: string, severity: TSnackbarSeverity) => {
        dispatcher({ type: 'SHOW_MESSAGE', payload: { message, severity } });
    },
    showError: (dispatcher: React.Dispatch<IReducerAction<TSnackbarActions>>, message: string) => {
        dispatcher({ type: 'SHOW_MESSAGE', payload: { message, severity: 'error' } });
    },
};

interface ISnackbarProviderProps {
    children: any;
};
export const SnackbarContextProvider: FunctionComponent<ISnackbarProviderProps> = (props: ISnackbarProviderProps) => {
    const [state, dispatch] = useReducer(SnackbarContextReducer, {...initialState,
        showSnackbar: (message: string, severity?: TSnackbarSeverity) => providerActions.showSnackbar(dispatch, message, severity ?? 'success'),
        showError: (message: string) => providerActions.showError(dispatch, message)
    });

    return (
        <SnackbarContext.Provider value={state}>
            {props.children}

            <Snackbar
                message={state.message}
                severity={state.severity}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
            />
        </SnackbarContext.Provider>
    );
}