import React, { FunctionComponent, useReducer, useEffect, useState } from 'react';

import { IReducerAction } from '@cw/models/shared';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useAppSettings } from '@cw/hooks';

type TStripeContextActions = 'SET_STRIPE_INSTANCE';

interface IStripeContext {
    initialized: boolean;
    stripe?: Stripe;
    calculateServiceFee: (total: number) => number;
}

const initialState: IStripeContext = {
    initialized: false,
    calculateServiceFee: () => 0
};
export const StripeContext = React.createContext<IStripeContext>(initialState);

const StripeContextReducer = (state: IStripeContext, action: IReducerAction<TStripeContextActions>): IStripeContext => {
    switch (action.type) {
        case 'SET_STRIPE_INSTANCE':
            return { ...state, initialized: !!action.payload, stripe: action.payload };
        default:
            return state;
    }
}

interface IStripeContextProviderProps {
    children: any;
}
export const StripeContextProvider: FunctionComponent<IStripeContextProviderProps> = (props: IStripeContextProviderProps) => {
    const [state, dispatch] = useReducer(StripeContextReducer, {...initialState});

    const [stripeKeyToUse, setStripeKeyToUse] = useState('');
    const { appSettings } = useAppSettings();

    const calculateServiceFee = (total: number): number => {
      if (total <= 0) {
        return 0;
      }
      const percentageFee = total * (appSettings.stripe.serviceFeePercentage / 100);
      return Math.ceil((percentageFee + appSettings.stripe.serviceFeeFixed) * 100) / 100;
    }

    useEffect(() => {
      if (appSettings && appSettings.stripe.publishKey !== stripeKeyToUse) {
        setStripeKeyToUse(appSettings.stripe.publishKey);
      }
    }, [appSettings, stripeKeyToUse]);

    useEffect(() => {
      if (!stripeKeyToUse) {
        return;
      }

      loadStripe(stripeKeyToUse).then(x => {
        dispatch({
          type: 'SET_STRIPE_INSTANCE',
          payload: x
        });
      });
    }, [stripeKeyToUse]);

    return (
        <StripeContext.Provider value={{
          ...state,
          calculateServiceFee
        }}>
            {props.children}
        </StripeContext.Provider>
    )
};