import React, { FunctionComponent, useReducer, useEffect, useState } from 'react';

import { FirebaseError, initializeApp } from 'firebase/app';
import {
  Auth,
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User,
  getAuth,
  indexedDBLocalPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword as createFirebaseUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification as sendFirebaseEmailVerification,
  applyActionCode,
  updateProfile,
  reload,
  verifyBeforeUpdateEmail
} from 'firebase/auth';

import { logger } from '@cw/services/logger';

import { IReducerAction } from '@cw/models/shared';
import { IFirebaseAuthResult, IFirebaseResult, IFirebaseUserDetails, TFirebaseAuthErrorType, TFirebaseAuthProvider } from '@cw/models/firebase';
import { useAppSettings } from '@cw/hooks';
import { recordAnalytic } from '@cw/services/analytics';

type TFirebaseContextActions = 'SET_INITIALIZED' | 'SET_AUTHORIZED' | 'SET_IS_AUTHORIZING' | 'SET_AUTHORIZED_USER';

interface IFirebaseContext {
  isInitialized: boolean;
  isAuthorized: boolean;
  isAuthorizing: boolean;
  authorizedUser: IFirebaseUserDetails | null;
  authenticateWithProvider: (provider: TFirebaseAuthProvider) => Promise<IFirebaseAuthResult>,
  authenticateWithEmailAndPassword: (email: string, password: string) => Promise<IFirebaseAuthResult>,
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<IFirebaseAuthResult>,
  signOut: () => Promise<void>;
  getAccessToken: () => Promise<string | null>;
  sendResetPasswordEmail: (email: string) => Promise<boolean>;
  resetUserPassword: (code: string, newPassword: string) => Promise<IFirebaseResult>;
  sendEmailVerification: () => Promise<boolean>;
  verifyEmailWithCode: (code: string) => Promise<IFirebaseResult>;
  sendChangeUserEmailEmail: (newEmail: string) => Promise<boolean>;
  verifyChangeUserEmailWithCode: (code: string) => Promise<IFirebaseResult>;
  updateUserDetails: (displayName: string) => Promise<boolean>;
}

const initialState: IFirebaseContext = {
  isInitialized: false,
  isAuthorized: false,
  isAuthorizing: false,
  authorizedUser: null,
  authenticateWithProvider: () => Promise.resolve({ success: false }),
  authenticateWithEmailAndPassword: () => Promise.resolve({ success: false }),
  createUserWithEmailAndPassword: () => Promise.resolve({ success: false }),
  signOut: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(null),
  sendResetPasswordEmail: () => Promise.resolve(false),
  resetUserPassword: () => Promise.resolve({ success: false }),
  sendEmailVerification: () => Promise.resolve(false),
  verifyEmailWithCode: () => Promise.resolve({ success: false }),
  sendChangeUserEmailEmail: () => Promise.resolve(false),
  verifyChangeUserEmailWithCode: () => Promise.resolve({ success: false }),
  updateUserDetails: () => Promise.resolve(false)
}
export const FirebaseContext = React.createContext<IFirebaseContext>(initialState);

const FirebaseContextReducer = (
  state: IFirebaseContext,
  action: IReducerAction<TFirebaseContextActions>
): IFirebaseContext => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return { ...state, isInitialized: action.payload };
    case 'SET_AUTHORIZED':
      return { ...state, isAuthorized: action.payload };
    case 'SET_IS_AUTHORIZING':
      return { ...state, isAuthorizing: action.payload };
    case 'SET_AUTHORIZED_USER':
      return { ...state, authorizedUser: action.payload };
    default:
      return state;
  }
};

const mapToAuthResult = (user: User): IFirebaseAuthResult => ({
  success: true,
  userDetails: mapToAuthUser(user)
});
const mapToAuthUser = (user: User): IFirebaseUserDetails => ({
  uid: user.uid,
  email: user.email,
  name: getNameParts(user.displayName).name,
  surname: getNameParts(user.displayName).surname,
  photoUrl: user.photoURL,
  emailVerified: user.emailVerified
})

const getNameParts = (displayName: string | null): { name: string | null, surname: string | null } => {
  const parts = (displayName ?? '').split(' ');
  const name = parts[0] ?? '';
  const surname = (displayName ?? '').replace(name, '').trim();
  return {
    name: name || null,
    surname: surname || null
  };
}

const getFirebaseErrorType = (err: FirebaseError): TFirebaseAuthErrorType => {
  const errorMessage: string = ((err.message ?? '') as string).toLowerCase().trim();

  if (err.code === 'auth/email-already-in-use') {
    return 'conflict';
  } else if (err.code === 'auth/invalid-credential' || err.code === 'auth/invalid-email' || err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
    return 'not_found';
  } else if (err.code === 'auth/popup-closed-by-user' || err.code === 'auth/user-cancelled' || err.code === 'auth/cancelled-popup-request' || err.code === '1001' || errorMessage.includes('the user canceled the sign-in flow') || errorMessage.includes('authorizationError error 1001')) {
    return 'user_cancelled';
  } else if (err.code === 'auth/invalid-action-code') {
    return 'invalid-action-code';
  } else if (err.code === 'auth/network-request-failed') {
    return 'network_error';
  } else if (err.code === 'account-exists-with-different-credential') {
    return 'account_exists_with_provider';
  } else {
    return 'unknown';
  }
}

interface IFirebaseContextProviderProps {
  children: any;
}
export const FirebaseContextProvider: FunctionComponent<IFirebaseContextProviderProps> = (props: IFirebaseContextProviderProps) => {
  const [state, dispatch] = useReducer(FirebaseContextReducer, initialState);

  const { appSettings } = useAppSettings();

  const [firebaseAuth, setFirebaseAuth] = useState<Auth | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const authenticateWithProvider = async (provider: TFirebaseAuthProvider): Promise<IFirebaseAuthResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      let authProvider: AuthProvider | null = null;
  
      if (provider === 'google') {
        const google = new GoogleAuthProvider();
        google.addScope('profile');
        google.addScope('email');
        authProvider = google;
      } else if (provider === 'facebook') {
        const fb = new FacebookAuthProvider();
        fb.addScope('public_profile');
        fb.addScope('email');
        authProvider = fb;
      }
  
      if (!authProvider) {
        throw new Error(`Provider "${provider}" is not implemented!`);
      }
  
      const result = await signInWithPopup(firebaseAuth!, authProvider);
      return mapToAuthResult(result.user);
    } catch (err: any) {
      const errorType = getFirebaseErrorType(err);

      const errorMessage: string = ((err.message ?? '') as string).toLowerCase().trim();
      if (errorType === 'unknown' && !errorMessage.includes('the user canceled the sign-in flow') && !errorMessage.includes('authorizationerror error 1001') && !errorMessage.includes('sign in canceled')) {
        logger.error('Error during authenticateWithProvider', err);
      }
  
      return {
        success: false,
        errorType: errorType
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const authenticateWithEmailAndPassword = async (email: string, password: string): Promise<IFirebaseAuthResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      const result = await signInWithEmailAndPassword(firebaseAuth!, email, password);
      return mapToAuthResult(result.user);
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during authenticateWithEmailAndPassword', err);
      }
  
      return {
        success: false,
        errorType: errorType
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const createUserWithEmailAndPassword = async (email: string, password: string): Promise<IFirebaseAuthResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      const result = await createFirebaseUserWithEmailAndPassword(firebaseAuth!, email, password);
      return mapToAuthResult(result.user);
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during createUserWithEmailAndPassword', err);
      }
  
      return {
        success: false,
        errorType: errorType
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const signOut = async (): Promise<void> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await firebaseSignOut(firebaseAuth!);
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during signOut: ', err);
      }
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const getAccessToken = async (): Promise<string | null> => {
    try {
      if (!firebaseUser) {
        return null;
      }

      const accessToken = await firebaseUser.getIdToken();
      return accessToken ?? null;
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during getAccessToken', err);
      }
      return null;
    }
  }

  const sendResetPasswordEmail = async (email: string): Promise<boolean> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await sendPasswordResetEmail(firebaseAuth!, email, {
        url: window.location.origin
      });
      return true;
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during sendResetPasswordEmail', err);
      }
      return false;
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const resetUserPassword = async (code: string, newPassword: string): Promise<IFirebaseResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await confirmPasswordReset(firebaseAuth!, code, newPassword);
      return { success: true };
    } catch (err) {
      const firebaseError = getFirebaseErrorType(err as any);
      if (firebaseError === 'unknown') {
        logger.error('Error during resetUserPassword', err);
      }
      return {
        success: false,
        errorType: firebaseError
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const sendEmailVerification = async (): Promise<boolean> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await sendFirebaseEmailVerification(firebaseUser!, {
        url: window.location.origin
      });
      return true;
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during sendEmailVerification', err);
      }
      return false;
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const verifyEmailWithCode = async (code: string): Promise<IFirebaseResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await applyActionCode(firebaseAuth!, code);
      if (firebaseUser) {
        await reload(firebaseUser);
        setFirebaseUser(firebaseUser);
        dispatch({
          type: 'SET_AUTHORIZED_USER',
          payload: firebaseUser ? mapToAuthUser(firebaseUser) : null
        });
      }
      return { success: true };
    } catch (err) {
      const firebaseError = getFirebaseErrorType(err as any);
      if (firebaseError === 'unknown') {
        logger.error('Error during verifyEmailWithCode', err);
      }
      return {
        success: false,
        errorType: firebaseError
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const updateUserDetails = async (displayName: string): Promise<boolean> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await updateProfile(firebaseUser!, {
        displayName
      });
      return true;
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during updateUserDetails', err);
      }
      return false;
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const sendChangeUserEmailEmail = async (newEmail: string): Promise<boolean> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await verifyBeforeUpdateEmail(firebaseUser!, newEmail);
      return true;
    } catch (err) {
      const errorType = getFirebaseErrorType(err as any);
      if (errorType === 'unknown') {
        logger.error('Error during sendChangeUserEmailEmail', err);
      }
      return false;
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  const verifyChangeUserEmailWithCode = async (code: string): Promise<IFirebaseResult> => {
    dispatch({ type: 'SET_IS_AUTHORIZING', payload: true });
    try {
      await applyActionCode(firebaseAuth!, code);
      await signOut();
      return { success: true };
    } catch (err) {
      const firebaseError = getFirebaseErrorType(err as any);
      if (firebaseError === 'unknown') {
        logger.error('Error during verifyChangeUserEmailWithCode', err);
      }
      return {
        success: false,
        errorType: firebaseError
      };
    } finally {
      dispatch({ type: 'SET_IS_AUTHORIZING', payload: false });
    }
  }

  // Initialize firebase setup
  useEffect(() => {
    if (window.location.host === appSettings.mobileAppWebsiteBaseDomain) {
      return;
    }

    try {
      let initStartTime: Date | null = new Date();

      const firebaseAppSettingsToUse = appSettings.isInternalAdminMode
        ? appSettings.firebaseInternal
        : appSettings.firebase;

      const app = initializeApp({
        apiKey: firebaseAppSettingsToUse.apiKey,
        authDomain: firebaseAppSettingsToUse.authDomain,
        projectId: firebaseAppSettingsToUse.projectId,
        storageBucket: firebaseAppSettingsToUse.storageBucket,
        measurementId: firebaseAppSettingsToUse.measurementId
      });
  
      const auth = getAuth(app);
      auth.setPersistence(indexedDBLocalPersistence);
  
      setFirebaseAuth(auth);
      
      auth.authStateReady().then(async () => {
        setFirebaseUser(auth.currentUser);
  
        dispatch({ type: 'SET_AUTHORIZED', payload: !!auth.currentUser });
        dispatch({ type: 'SET_INITIALIZED', payload: true });

        if (!!initStartTime) {
          recordAnalytic('FirebaseInitialized', {
            initDurationMs: new Date().getTime() - initStartTime.getTime()
          });
          initStartTime = null;
        }
      }).catch((err) => {
        logger.error('Error during FirebaseContextProvider::useEffect::authStateReady ', err);
      });
    } catch (err: any) {
      if (err.code !== 'app/duplicate-app') {
        logger.error('Error during FirebaseContextProvider::useEffect', err);
      }
    }
  }, [appSettings]);

  useEffect(() => {
    if (!firebaseAuth) {
      return;
    }

    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setFirebaseUser(user);
      dispatch({ type: 'SET_AUTHORIZED', payload: !!user });
    });

    return () => unsubscribe();
  }, [firebaseAuth]);

  useEffect(() => {
    dispatch({
      type: 'SET_AUTHORIZED_USER',
      payload: firebaseUser ? mapToAuthUser(firebaseUser) : null
    });
  }, [firebaseUser]);

  return (
    <FirebaseContext.Provider value={{
      ...state,
      authenticateWithProvider,
      authenticateWithEmailAndPassword,
      createUserWithEmailAndPassword,
      signOut,
      getAccessToken,
      sendResetPasswordEmail,
      resetUserPassword,
      sendEmailVerification,
      verifyEmailWithCode,
      updateUserDetails,
      sendChangeUserEmailEmail,
      verifyChangeUserEmailWithCode
    }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
