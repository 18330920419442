import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './styles/Avatar.scss';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { AddAPhoto, Edit } from '@mui/icons-material';

import { classNames } from '@cw/utils';
import { getDeviceImage, IDeviceImage } from '@cw/services/imagePickerService';

import { getColorForInitials } from '@cw/utils/getColorForInitials';
import { useAppSettings } from '@cw/hooks';
import { TIconSize } from '@cw/components/icons';

interface IAvatarProps {
  fullName?: string;
  imageKey?: string;
  size?: TIconSize;
  canEdit?: boolean;
  forceImageUpload?: boolean;
  onNewPictureSelected?: (image: IDeviceImage) => void;
  addImageText?: string;
  shape?: 'square' | 'round';
  noBorder?: boolean;
  sx?: SxProps<Theme>;
}
export const Avatar: FunctionComponent<IAvatarProps> = (props: IAvatarProps) => {

  const { fullName, imageKey, size, canEdit, onNewPictureSelected, forceImageUpload, addImageText, shape, noBorder, sx } = props;
  const { appSettings } = useAppSettings();

  const [newImage, setNewImage] = useState<IDeviceImage>();

  const initials = useMemo(() => {
    if (!fullName) {
      return '';
    }
    const nameParts = fullName.split(' ').map(x => x.substring(0,1).toUpperCase());
    if (nameParts.length > 1) {
      return `${nameParts[0]}${nameParts[1]}`;
    }

    return nameParts[0];
  }, [fullName]);
  const color = useMemo(() => getColorForInitials(initials), [initials]);

  const imageUrl = useMemo(() => {
    return !imageKey ? '' : `${appSettings.blobBaseUrl}/${imageKey}`;
  }, [imageKey, appSettings]);

  const handleImageSelect = async () => {
    if (!canEdit) {
      return;
    }

    const image = await getDeviceImage();
    if (image) {
      setNewImage(image);
      if (onNewPictureSelected) {
        onNewPictureSelected(image);
      }
    }
  }

  useEffect(() => {
    setNewImage(undefined);
  }, [canEdit]);

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      className={classNames('cw-avatar-logo', `size--${size ?? 'medium'}`, `shape--${shape ?? 'round'}`, {
        [String(color)]: !imageKey && !forceImageUpload && !!initials && !newImage,
        'small-font': (canEdit && !imageKey && !initials && !newImage) ?? false,
        'editable': canEdit ?? false,
        'no-border': noBorder ?? false
      })}
      onClick={handleImageSelect}
      sx={sx}
    >
      {
        newImage ? (
          <img src={newImage.dataUrl} alt='' />
        ) : imageUrl ? (
          <img src={imageUrl} alt='' />
        ) : initials && !forceImageUpload ? (
          initials
        ) : null
      }

      {
        canEdit && !imageKey && (!initials || forceImageUpload) && !newImage ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            rowGap={1}
            direction='column'
          >
            <AddAPhoto fontSize={size === 'large' ? 'large' : 'medium'} />
            <Typography variant={size === 'large' ? 'body1' : size === 'medium' ? 'body3' : 'caption'}>{addImageText ?? 'Add Image'}</Typography>
          </Stack>
        ) : canEdit ? (
          <Stack className='edit-overlay' justifyContent='center' alignItems='center'>
            <Edit color='light' fontSize='small' />
          </Stack>
        ) : null
      }
    </Stack>
  )
}